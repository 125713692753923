export const routesReports = [
    {
        name: 'SalesReport',
        path: '/reports/sales',
        component: () => import('pages/Reports/Sales/Index.vue'),
    },
    {
        name: 'ClientReport',
        path: '/reports/client',
        component: () => import('pages/Reports/Client/Index.vue'),
    },
];
