<template>
    <q-list separator>
        <q-item>
            <q-item-section class="col-6"><b>Склад (ПВЗ)</b></q-item-section>
            <q-item-section><b>Ячейка</b></q-item-section>
            <q-item-section><b>Количество</b></q-item-section>
        </q-item>
        <q-item v-for="(item, i) in cells" :key="i">
            <q-item-section v-if="item.stock.region_name" class="col-6">
                <span>{{ item.stock.region_name }} :: {{ item.stock.name }} @{{ item.stock.address }}</span>
            </q-item-section>
            <q-item-section>
                <span>{{ item.barcode }}</span>
            </q-item-section>
            <q-item-section>
                <span>{{ item.quantity }}</span>
            </q-item-section>
        </q-item>
    </q-list>
</template>

<script>
export default {
    props: {
        cells: {
            type: Array,
            default: () => [],
        },
    },
};
</script>
