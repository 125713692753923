export const routesStock = [
    // {
    //     name: 'StockReception',
    //     path: '/stock/reception',
    //     component: () => import('pages/Stock/Reception.vue'),
    // },
    // {
    //     name: 'Returns2Principals',
    //     path: '/stock/returns',
    //     component: () => import('pages/Stock/Returns.vue'),
    // },
    {
        name: 'StocksIndex',
        path: '/stocks',
        props: true,
        component: () => import('pages/Stock/Index.vue'),
        meta: {
            help: '09bd5b1389384036879418c029c6fa12',
        },
    },
];
