<template>
    <div class="q-gutter-x-xs q-pr-md no-wrap">

        <q-btn-dropdown
            outline
            size="sm"
            no-caps
            auto-close
            unelevated
            menu-anchor="bottom left"
            menu-self="top left"
            color="primary"
            label="Печать"
        >
            <q-list>
                <q-item
                    v-if="barcode"
                    clickable
                    @click="$htmlToPaper(`print_${id}_${barcode}`)"
                >
                    <q-item-section>
                        <barcode
                            :id="`print_${id}_${barcode}`"
                            position="top"
                            label="DP.express"
                            :barcode="barcode"
                            :number="number"
                            with-logo
                            hidden
                        />
                        <q-item-label>Штрихкод DP.express</q-item-label>
                    </q-item-section>
                </q-item>

                <template v-if="media && media.length">
                    <q-item
                        v-for="(item, i) in media"
                        :key="`media_${id}_${i}`"
                        tag="a"
                        :href="item.url"
                        clickable
                        target="_blank"
                    >
                        <q-item-section>
                            <q-item-label>{{ collectionNameTrans(item.collection_name) }}</q-item-label>
                        </q-item-section>
                    </q-item>
                </template>

                <template v-if="sharedMedia && sharedMedia.length">
                    <q-item
                        v-for="(item, i) in sharedMedia"
                        :key="`sharedMedia_${id}_${i}`"
                        tag="a"
                        :href="item.doc.url"
                        clickable
                        target="_blank"
                    >
                        <q-item-section>
                            <q-item-label>{{ collectionNameTrans(item.doc.collection_name) }}</q-item-label>
                        </q-item-section>
                    </q-item>
                </template>

            </q-list>
        </q-btn-dropdown>

    </div>
</template>

<script>
import { orderState } from 'config/';
import BarcodeComponent from 'components/BarcodeComponent';

const DOC_LOCALS = {
    orders_stickers: 'Стикер/этикетка',
    dp_barcode: 'Штрихкод DP.express',
    carrier_barcode: 'Штрихкод курьерской службы',
    acceptance_acts: 'Акт приёмо-передачи',
    return_proxy: 'Доверенность на возвраты',
};

export default {
    components: {
        'barcode': BarcodeComponent,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
        barcode: {
            type: String,
        },
        number: {
            type: String,
        },
        media: {
            type: Array,
        },
        parcels: {
            type: Array,
        },
    },
    data() {
        return {
            orderState,
        };
    },
    computed: {
        sharedMedia(){
            return this.$attrs.shared_media;
        },
    },
    methods: {
        collectionNameTrans(name){
            return DOC_LOCALS[name];
        },
    },
};
</script>
