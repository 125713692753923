import { createEffect, restore } from 'effector';
import { AuthApi } from 'api/AuthApi';
import { redirectToAuth } from 'utils/helpers';

export const getCurrentUser = createEffect({ handler: () => AuthApi.getCurrentUser() });

export const authLogout = createEffect({ handler: () => AuthApi.logout() });

export const $authUser = restore(getCurrentUser, {}).reset(authLogout);

getCurrentUser.done.watch(({ result }) => {
    if (result.type === 'pvz') {
        window.location = process.env.OPERATOR_DOMAIN;
    }
});

authLogout.done.watch(redirectToAuth);
