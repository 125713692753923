<template>
    <div class="text-center absolute-center">
        <p>
            <q-icon name="warning" size="70px" color="negative" />
        </p>
        <p class="text-faded">
            Доступ запрещен
            <strong>(403)</strong>
        </p>
        <q-btn color="secondary" class="fit" label="Назад" @click="$router.push('/')"/>
    </div>
</template>

<script>
export default {
    name: 'Error404',
};
</script>
