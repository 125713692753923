import { createEffect, createEvent, createStore } from 'effector';
import { createCoeffect } from 'utils/effector';
import { LegalsApi } from 'api/LegalsApi';

/* Bank requisites */
export const updateRequisites = createEvent();
export const resetBankRequisites = createEvent();
export const $bankRequisites = createStore({})
    .on(updateRequisites, (state, params) => ({ ...state, ...params }))
    .reset(resetBankRequisites);

export const submitBankRequisites = createCoeffect($bankRequisites, (bank) => LegalsApi.createBank(bank));
export const updateBank = createEffect({ handler: (bank) => LegalsApi.updateBank(bank) });