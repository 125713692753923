<template>
    <q-form>
        <q-card flat class="bg-white">
            <q-card-section class="row q-pa-none">
                <slot name="form-body"/>
            </q-card-section>
        </q-card>
    </q-form>
</template>

<script>
export default {
    //
};
</script>
