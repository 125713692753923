<template>
    <q-input
        v-model="localValue"
        v-bind="{...$attrs, ...$props}"
        :readonly="!edit"
        :loading="loading"
        :type="type"
        outlined
        :class="{'bg-grey-3': !edit && !disabled, 'base-update-input': true}"
        @keyup.enter="handleUpdate"
    >
        <template v-if="!loading && !disabled" #append>
            <q-btn v-show="!edit" :size="dense? 'sm' : 'md'" color="primary" round flat icon="edit" @click="edit=true" />
            <q-btn
                v-show="edit"
                :size="dense? 'sm' : 'md'"
                color="positive"
                round
                flat
                icon="check"
                @click="handleUpdate"
            />
        </template>
        <slot />
    </q-input>
</template>

<script>
export default {
    props: {
        required: Boolean,
        disabled: Boolean,
        type: {
            type: String,
            default: 'text',
        },
        label: String,
        value: [String, Number],
        dense: Boolean,
        callback: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            localValue: this.value,
            edit: false,
            loading: false,
        };
    },
    watch: {
        value(newValue) {
            this.type == 'number' ? this.localValue = Number(newValue > 0 && newValue) : '';
        },
    },
    methods: {
        async handleUpdate() {
            if (!this.edit) return;

            this.edit = false;
            this.loading = true;
            try {
                await this.callback(this.localValue);
            } catch (e) {
                this.localValue = this.value;
            }
            this.loading = false;
        },
    },
};
</script>

<style lang="stylus">
.base-update-input {
  .q-field__control {
    padding-right: 4px !important;
  }
}
</style>
