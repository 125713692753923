// import { $userCanEditLegal, $userCanEditStocks, $userIsSuperadmin, $userCanEditUsers } from './permissions';
import { combine } from 'effector';

export const $menu = combine(
    () => [
        {
            name: 'Главная',
            routeName: 'Dashboard',
            icon: 'dashboard',
        },
        {
            name: 'Заказы',
            routeName: 'Orders',
            icon: 'view_module',
        },
        {
            name: 'Возвраты',
            routeName: 'Returns',
            icon: 'view_quilt',
        },
        /* {
            name: 'Отгрузки',
            routeName: 'Shippings',
            icon: 'widgets',
        },
        {
            name: 'Товары',
            routeName: 'Products',
            icon: 'category',
        },
        {
            name: 'Остатки',
            routeName: 'Residues',
            icon: 'view_quilt',
        }, */
        {
            name: 'Отчеты',
            icon: 'view_list',
            items: [
                // {
                //     name: 'Отчёт о продажах',
                //     routeName: 'SalesReport',
                // },
                {
                    name: 'Отчёт клиента',
                    routeName: 'ClientReport',
                },
            ],
        },
        {
            name: 'Организация',
            icon: 'account_tree',
            items: [
                {
                    name: 'Информация',
                    routeName: 'LegalEdit',
                },
                {
                    name: 'Склады',
                    routeName: 'StocksIndex',
                },
            ],
        },

    ]
);
