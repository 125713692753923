var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-input",
    _vm._b(
      {
        class: {
          "bg-grey-3": _vm.disable,
          "text-black": _vm.disable,
          "q-field--denser": _vm.denser
        },
        attrs: {
          outlined: "",
          "label-slot": !!_vm.label,
          "stack-label": !!_vm.label,
          "hide-bottom-space": "",
          dense: _vm.dense
        },
        on: {
          input: function(value) {
            return _vm.$emit("input", value)
          },
          change: function(event) {
            return _vm.$emit("change", event.target.value)
          }
        },
        scopedSlots: _vm._u(
          [
            !!_vm.label
              ? {
                  key: "label",
                  fn: function() {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.label) + " "),
                        _vm.required
                          ? _c("b", { staticClass: "text-negative" }, [
                              _vm._v("*")
                            ])
                          : _vm._e()
                      ])
                    ]
                  },
                  proxy: true
                }
              : null,
            {
              key: "hint",
              fn: function() {
                return [_vm._v("\n        " + _vm._s(_vm.hint) + "\n    ")]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      },
      "q-input",
      Object.assign({}, _vm.$attrs, _vm.$props),
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }