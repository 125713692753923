var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row q-col-gutter-md" },
    _vm._l(_vm.options, function(option, j) {
      return _c(
        "div",
        {
          key: "option_" + option.name + "_" + j,
          class: option.colClass || "col"
        },
        [
          option.type === "label"
            ? _c("h6", { staticClass: "text-subtitle2 q-ma-none" }, [
                _vm._v(_vm._s(option.label))
              ])
            : option.type === "checkbox"
            ? _c("q-checkbox", {
                attrs: {
                  label: option.label,
                  readonly: !!_vm.item.readonly || !!option.readonly
                },
                model: {
                  value: _vm.item[option.name],
                  callback: function($$v) {
                    _vm.$set(_vm.item, option.name, $$v)
                  },
                  expression: "item[option.name]"
                }
              })
            : option.type === "select"
            ? _c("base-select", {
                attrs: {
                  label: option.label,
                  options: option.options,
                  "emit-value": "",
                  "map-options": "",
                  dense: "",
                  stacked: "",
                  required: option.required,
                  readonly: !!_vm.item.readonly || !!option.readonly
                },
                on: { input: _vm.update },
                model: {
                  value: _vm.item[option.name],
                  callback: function($$v) {
                    _vm.$set(_vm.item, option.name, $$v)
                  },
                  expression: "item[option.name]"
                }
              })
            : _c(
                "base-input",
                _vm._b(
                  {
                    attrs: {
                      dense: "",
                      stacked: "",
                      readonly: !!_vm.item.readonly || !!option.readonly
                    },
                    on: {
                      input: function($event) {
                        return _vm.update()
                      }
                    },
                    model: {
                      value: _vm.item[option.name],
                      callback: function($$v) {
                        _vm.$set(_vm.item, option.name, $$v)
                      },
                      expression: "item[option.name]"
                    }
                  },
                  "base-input",
                  option,
                  false
                )
              )
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }