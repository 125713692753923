export const routesProduct = [
    {
        name: 'Products',
        path: '/products',
        component: () => import('pages/Products/Index.vue'),
    },
    // {
    //     name: 'ProductsCreate',
    //     path: '/products/create',
    //     component: () => import('pages/Products/Create.vue'),
    // },
    // {
    //     name: 'ProductsEdit',
    //     path: '/couriers/:id',
    //     props: true,
    //     component: () => import('pages/Products/Form.vue'),
    // },
];
