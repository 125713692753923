<template>
    <div class="relative-position">
        <slot />
        <q-inner-loading :showing="value">
            <q-spinner-ios :size="size" class="text-accent" />
            <span class="loader-text">{{ text }}</span>
        </q-inner-loading>
    </div>
</template>

<script>
export default {
    props: {
        value: Boolean,
        size: {
            type: String,
            default: 'md',
        },
        text: String,
    },
};
</script>