import { orderServicesPaying } from '..';

/**
 * Data types definition
 *
 * @enum {number}
 * @property {number} PRODUCT
 * @property {number} DELIVERY
 * @property {number} ITEM
 * @property {number} CONDITION
 */
export const dataType = {
    PRODUCT: 0,
    DELIVERY: 3,
    ITEM: 7,
    CONDITION: 10,
};

export const dataStatus = {
    NOT_ACCEPTED: 0,
    IN_STORE: 10,
    REJECTED: 20,
    DELIVERED: 30,
    RETURNED: 50,
};

const dataStatusNames = {
    0: 'Не принят',
    10: 'На складе',
    20: 'Отказ',
    25: 'Частичный отказ',
    30: 'Выдан',
    50: 'Возвращен',
};

export const getDataStatusName = (status) => dataStatusNames[status];

export const dataState = {
    IN_STORE: 10, // На складе
    SHIPPED: 20, // Отгружен
    COURIER: 30, // У курьера
    Issued: 40, // Филиал
    RETURN_TO_STORE: 50, // Отдано на склад для возврата
    POSTPONE: 60, // Перенос
    DEFECT: 70, // Брак
};

const dataStateNames = {
    10: 'На складе',
    20: 'Отгружен',
    30: 'У курьера',
    40: 'Филиал',
    50: 'Отдано на склад для возврата',
    60: 'Перенос',
    70: 'Брак',
};

export const getDataStateName = (state) => dataStateNames[state];

export const conditions = {
    10000: 'Частичная выдача',
    20000: 'Вскрытие заказа',
    30000: 'Примерка',
    50000: 'Оплата наличными',
    60000: 'Оплата б/картой',
};

/**
 * Set of required order data fields by type
 *
 * @param {int} type
 * @param {int} orderService
 *
 * @returns {Set}
 */
export const requiredDataFields = (type, orderService) => {
    const payable = orderServicesPaying.includes(orderService);
    const fields = new Set(['type']);

    switch (type) {
        case dataType.PRODUCT:
            ['article', 'name', 'qty', 'weight', 'width', 'height', 'length', 'service'].forEach(fields.add, fields);
            payable && ['price', 'declared_price'].forEach(fields.add, fields);
            break;

        case dataType.DELIVERY:
            ['service'].forEach(fields.add, fields);
            payable && ['price'].forEach(fields.add, fields);
            break;

        case dataType.ITEM:
            ['qty', 'weight', 'width', 'height', 'length'].forEach(fields.add, fields);
            break;

        case dataType.CONDITION:
            ['article'].forEach(fields.add, fields);
            break;
    }

    return fields;
};

/**
 * Set of editable order data fields by type
 *
 * @param {int} type
 * @param {int} orderService
 *
 * @returns {Set}
 */
export const ediableDataFields = (type, orderService) => {
    const payable = orderServicesPaying.includes(orderService);
    const fields = new Set(['type']);

    switch (type) {
        case dataType.PRODUCT:
            ['article', 'name', 'barcode', 'qty', 'weight', 'width', 'height', 'length', 'service'].forEach(fields.add, fields);
            payable && ['price', 'price_card', 'declared_price'].forEach(fields.add, fields);
            break;

        case dataType.DELIVERY:
            ['service'].forEach(fields.add, fields);
            payable && ['price', 'price_card'].forEach(fields.add, fields);
            break;

        case dataType.ITEM:
            ['barcode', 'qty', 'weight', 'width', 'height', 'length'].forEach(fields.add, fields);
            break;

        case dataType.CONDITION:
            ['article', 'service'].forEach(fields.add, fields);
            break;
    }

    return fields;
};
