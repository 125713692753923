var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center absolute-center" },
    [
      _c(
        "p",
        [
          _c("q-icon", {
            attrs: { name: "warning", size: "70px", color: "negative" }
          })
        ],
        1
      ),
      _vm._m(0),
      _c("q-btn", {
        staticClass: "fit",
        attrs: { color: "secondary", label: "Назад" },
        on: {
          click: function($event) {
            return _vm.$router.push("/")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "text-faded" }, [
      _vm._v("\n        Доступ запрещен\n        "),
      _c("strong", [_vm._v("(403)")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }