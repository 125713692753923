<template>
    <q-select
        v-bind="{...$attrs, ...$props}"
        outlined
        :label-slot="!!label"
        :stack-label="!!label"
        :dense="dense"
        :class="{
            'bg-grey-3': disable,
            'text-black': disable,
            'q-field--denser': denser,
        }"
        @input="(value) => $emit('input', value)"
        @change="(value) => $emit('change', value)"
    >
        <template v-if="!!label" #label>
            <span>{{ label }} <b v-if="required" class="text-negative">*</b></span>
        </template>
        <template #no-option>
            <q-item>
                <q-item-section class="text-grey">
                    Ничего не найдено
                </q-item-section>
            </q-item>
        </template>
    </q-select>
</template>

<script>
export default {
    props: {
        disable: Boolean,
        readonly: Boolean,
        required: Boolean,
        label: String,
        value: [String, Number, Boolean, Object],
        dense: Boolean,
        denser: Boolean,
    },
};
</script>
