export const payMethod = {
    cash: 'Наличные',
    cashless: 'Карта',
};

export const pickupMethod = {
    pickup: 'Выдача',
    return: 'Возврат',
};

export const weekDays = {
    monday: 'Понедельник',
    tuesday: 'Вторник',
    wednesday: 'Среда',
    thursday: 'Четверг',
    friday: 'Пятница',
    saturday: 'Суббота',
    sunday: 'Воскресенье',
};

export const contactTypes = {
    phone: 'Телефон',
    email: 'Email',
    url: 'Адрес сайта',
};

export const contactTypesOptions = () => {
    return Object.keys(contactTypes)
        .map(key => ({value: key, label: contactTypes[key]}));
};


export const taxTypes = {
    osn: 'Общая',
    usnIncome: 'Упрощенная (Доход)',
    usnIncomeOutcome: 'Упрощенная (Доход минус Расход)',
    envd: 'Единый налог на вмененный доход',
    esn: 'Единый сельскохозяйственный налог',
    patent: 'Патентная система налогообложения',
};

export const taxTypesOptions = () => {
    return Object.keys(taxTypes)
        .map(key => ({ value: key, label: taxTypes[key] }));
};