var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        version: "1.0",
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 800 800",
        preserveAspectRatio: "xMidYMid meet"
      }
    },
    [
      _c("g", { attrs: { fill: _vm.centerFill } }, [
        _c("circle", { attrs: { cx: "400", cy: "400", r: "240" } }),
        _c("polygon", {
          attrs: {
            points: "0,0 0,200 80,300 300,300 300,100 220,0",
            transform: "translate(583 99) scale(0.5)"
          }
        })
      ]),
      _c("g", { attrs: { fill: _vm.orbitFill } }, [
        _c("path", {
          attrs: {
            d:
              "M381.5 794.3 c-3.3 -0.2 -12.7 -1 -21 -1.9 -172 -17.9 -313.6 -151.6 -342.9 -323.9 -4.5 -26 -5.1 -34.6 -5.1 -68.5 0 -34 0.6 -41.8 5.1 -68 18.2 -106.7 79.8 -201.2 170.4 -261.7 44.6 -29.7 94.8 -49.7 147.2 -58.7 25.8 -4.5 33.9 -5.1 66.8 -5.1 24.6 0 34.6 0.4 45.5 1.8 55.7 7.3 106.3 24.8 152.3 52.7 15.8 9.6 34.4 22.9 33.7 24 -0.4 0.6 -15.2 1 -37.4 1 -36.8 0 -36.8 0 -47.7 -5.1 -33.9 -15.9 -66.4 -25.4 -104.9 -30.5 -16.3 -2.2 -62.6 -2.6 -78.5 -0.6 -70.5 8.6 -129.8 33.6 -182.9 76.9 -13.6 11.2 -37.2 34.8 -48.4 48.4 -56.1 68.8 -84.2 154.4 -79.6 242.9 3.9 75.8 29.8 144.8 76.5 203.5 11.8 14.8 43.2 46 57.4 56.9 50.6 39 101.7 60.9 165 70.8 12.9 2 18.3 2.2 48 2.3 26.8 0 36 -0.4 46 -1.8 76.1 -10.8 141.8 -42.7 195.7 -95 33.1 -32.2 57.5 -67.5 75.3 -109.2 35.7 -83.5 38.2 -187 6.5 -270.4 -2.5 -6.5 -4.5 -12.3 -4.5 -13 0 -0.7 3.3 -1.1 10.4 -1.1 5.7 0 11.1 -0.3 12 -0.6 1.4 -0.5 1.6 -3.3 1.6 -20.5 0 -13.5 0.3 -19.9 1.1 -19.9 1.4 0 10.4 19.3 17.2 37 31 80.9 34.7 174.3 10.2 259.7 -6.6 22.9 -13.5 40.5 -25 63.8 -20.4 41.6 -42.2 71.4 -76.1 104.5 -77.4 75.5 -181.8 114.8 -289.9 109.3z"
          }
        })
      ]),
      _c("g", { attrs: { fill: _vm.lettersFill } }, [
        _c("path", {
          attrs: {
            d:
              "M251.5 495.8 c-0.3 -0.7 -0.4 -45.6 -0.3 -99.8 0.3 -98.5 0.3 -98.5 42.8 -98.5 37.8 0 43.4 0.2 50.5 1.9 18.5 4.2 33.1 12.3 46.6 25.6 20.3 20 29.1 45.1 27.6 78 -2.5 51.2 -34.9 86.7 -85 93 -12.4 1.5 -81.6 1.3 -82.2 -0.2z m84.1 -52.7 c14.1 -6.1 21.4 -22.2 21.4 -46.8 -0.1 -23.8 -7.3 -39.3 -21.3 -45.3 -6.6 -2.9 -23.4 -4.1 -24.8 -1.9 -1 1.6 -1.2 92.8 -0.2 95.4 0.9 2.6 17.9 1.6 24.9 -1.4z"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M437.5 495.8 c-0.3 -0.7 -0.4 -45.6 -0.3 -99.8 0.3 -98.5 0.3 -98.5 38.6 -98.8 41.8 -0.2 50.7 0.5 65.7 5.2 31.5 10 47.2 36.3 45.2 75.6 -1.6 29.3 -13.5 48.4 -36.9 58.9 -11 5 -28.1 8 -45 8.1 -6.8 0 -6.8 0 -7 25.8 -0.3 25.7 -0.3 25.7 -30.1 26 -23.3 0.2 -29.9 0 -30.2 -1z m78.2 -101.9 c7.4 -2.8 11.5 -11.1 11.5 -23.2 0 -9.3 -1.5 -14.9 -5.3 -19.2 -3.8 -4.3 -8.4 -5.7 -16.9 -5.3 -6.5 0.3 -6.5 0.3 -6.8 23.3 -0.1 12.6 0 23.5 0.2 24.2 0.7 1.6 13.2 1.8 17.3 0.2z"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }