var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relative-position" },
    [
      _vm._t("default"),
      _c(
        "q-inner-loading",
        { attrs: { showing: _vm.value } },
        [
          _c("q-spinner-ios", {
            staticClass: "text-accent",
            attrs: { size: _vm.size }
          }),
          _c("span", { staticClass: "loader-text" }, [_vm._v(_vm._s(_vm.text))])
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }