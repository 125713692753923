import { connection } from 'api/connection';
import { handleRequest } from './request';
import qs from 'qs';

export class ApiInstance {
    constructor(url) {
        this.baseUrl = url;
    }

    async getAll(params = {}) {
        return await handleRequest(connection.get(`${this.baseUrl}`, {
            params: params,
            paramsSerializer: params => {
                return qs.stringify(params);
            },
        }));
    }

    async get(id, params = {}) {
        return await handleRequest(connection.get(`${this.baseUrl}/${id}`, {
            params: params,
            paramsSerializer: params => {
                return qs.stringify(params);
            },
        }));
    }

    async create(model) {
        return await handleRequest(connection.post(`${this.baseUrl}`, model));
    }

    async update(model, id) {
        return await handleRequest(connection.patch(`${this.baseUrl}/${id || model.id}`, model));
    }

    async delete(id) {
        return await handleRequest(connection.delete(`${this.baseUrl}/${id}`));
    }
}
