var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-drawer",
    {
      staticClass: "app-sidebar",
      attrs: {
        value: true,
        mini: _vm.minifySidebar,
        width: 250,
        side: "left",
        "content-class": "bg-blue-grey-10",
        "show-if-above": ""
      },
      on: {
        mouseover: function($event) {
          _vm.minifySidebar = !_vm.value ? _vm.value : !_vm.value
        },
        mouseout: function($event) {
          _vm.minifySidebar = _vm.value
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "sidebar-menu" },
        [
          _c(
            "q-list",
            [
              _vm._l(_vm.menu, function(menuItem, menuIndex) {
                return [
                  menuItem.items && (!("allow" in menuItem) || menuItem.allow)
                    ? _c(
                        "q-expansion-item",
                        {
                          key: menuIndex,
                          attrs: {
                            "header-class": "expand-header",
                            "active-class": "bg-blue-grey-3",
                            "expand-separator": "",
                            group: "menuGroup",
                            icon: menuItem.icon,
                            label: menuItem.name,
                            "default-opened": _vm.hasActiveRouteChild(
                              menuItem.items
                            )
                          }
                        },
                        [
                          _vm._l(menuItem.items, function(item, itemIndex) {
                            return [
                              !("allow" in item) || item.allow
                                ? _c(
                                    "q-item",
                                    {
                                      key: itemIndex,
                                      attrs: { to: { name: item.routeName } }
                                    },
                                    [
                                      "icon" in menuItem
                                        ? _c(
                                            "q-item-section",
                                            { attrs: { avatar: "" } },
                                            [
                                              _c("q-icon", {
                                                attrs: { name: item.icon }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c("q-item-section", [
                                        _vm._v(_vm._s(item.name))
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      )
                    : !("allow" in menuItem) || menuItem.allow
                    ? _c(
                        "q-item",
                        {
                          key: menuIndex,
                          attrs: {
                            to: {
                              name:
                                menuItem.routeName ||
                                menuItem.items[0].routeName
                            }
                          }
                        },
                        [
                          "icon" in menuItem
                            ? _c(
                                "q-item-section",
                                { attrs: { avatar: "" } },
                                [
                                  _c("q-icon", {
                                    attrs: { name: menuItem.icon }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("q-item-section", [_vm._v(_vm._s(menuItem.name))])
                        ],
                        1
                      )
                    : _vm._e()
                ]
              })
            ],
            2
          )
        ],
        1
      ),
      _c("app-sidebar-user", { staticClass: "sidebar-toolbar absolute-top" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }