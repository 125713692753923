import { remapPagination, isEmpty } from 'utils/helpers';

const isPaginatedResponse = (response) => !isEmpty(response.pagination);

export const handleRequest = async (response) => {
    const { data } = await response;

    if (isPaginatedResponse(data)) {
        return { data: data.data, pagination: remapPagination(data.pagination) };
    }

    return data.data;
};

export const handleCacheRequest = async (response, suffix, cachingHours) => {
    const lastUpdate = sessionStorage.getItem(`update_date_${suffix}`);
    let data;

    if (lastUpdate > new Date().setHours(new Date().getHours() + cachingHours) || !lastUpdate) {
        data = await handleRequest(response());

        sessionStorage.setItem(`update_date_${suffix}`, new Date());
        sessionStorage.setItem(`data_${suffix}`, JSON.stringify(data));
    } else {
        data = JSON.parse(sessionStorage.getItem(`data_${suffix}`));
    }

    return data;
};

export const handleFileRequest = async (response, filename = null) => {
    const { data, headers } = await response;

    const blob = new Blob([data], { type: headers['content-type'] });

    const a = document.createElement('a');
    a.target = '_blank';
    a.href = URL.createObjectURL(blob);
    a.download = filename || headers['content-disposition'].match(/filename=(.*)/)[1]; //filename of the file
    a.click();

    return true;
};
