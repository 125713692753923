var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-list",
    { staticClass: "user-toolbar" },
    [
      _c(
        "q-item",
        { attrs: { dark: "" } },
        [
          _c(
            "q-item-section",
            { attrs: { avatar: "" } },
            [
              _c("logo-svg", {
                attrs: {
                  height: "42px",
                  width: "42px",
                  "center-fill": "#ffffff",
                  "orbit-fill": "#ff6c08",
                  "letters-fill": "#222"
                }
              })
            ],
            1
          ),
          _c(
            "q-item-section",
            { attrs: { "no-wrap": "" } },
            [
              _c("q-item-label", [_vm._v(_vm._s(_vm.user.login))]),
              _c(
                "q-item-label",
                { staticClass: "ellipsis", attrs: { caption: "" } },
                [_vm._v(_vm._s(_vm.user.name))]
              )
            ],
            1
          ),
          _c(
            "q-item-section",
            { attrs: { side: "" } },
            [
              _c("q-btn", {
                attrs: {
                  dense: "",
                  round: "",
                  flat: "",
                  color: "white",
                  title: "Выйти",
                  icon: "exit_to_app"
                },
                on: { click: _vm.authLogout }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }