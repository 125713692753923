import { createEffect, createEvent, createStore } from 'effector';

export const resetValidationErrors = createEvent();
export const setValidationErrors = createEffect({ handler: (errors) => errors });

export const $validationErrors = createStore({})
    .on(setValidationErrors, (_, validation) => {

        const newValidation = {};
        for (const [key, value] of Object.entries(validation)) {
            newValidation[key] = value.join(' || ');
        }

        return newValidation;
    })
    .reset(resetValidationErrors);