import { $userLegal } from 'store/user';
import { $authUser } from 'store/auth';

export const onlineChatOptions = () => {
    const legal = $userLegal.getState();
    const user = $authUser.getState();

    const { value: phoneNumber = user.phoneNumber } = legal.contacts.find(({ name }) => name === 'phone') || {};
    const { value: email = user.email } = legal.contacts.find(({ name }) => name === 'email') || {};

    jivo_api.setContactInfo({
        name: user.login,
        email: email,
        phone: phoneNumber,
    });

    jivo_api.setCustomData([
        {
            'title': 'Пользователь',
            'content': user.login,
            'link': `https://auth.dp.express/users/${user.id}/edit`,
        },
        {
            'title': 'Клиент',
            'content': legal.name,
            'link': `https://auth.dp.express/legals/${legal.id}/edit`,
        },
    ]);
};
