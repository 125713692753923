import { render, staticRenderFns } from "./AppSidebarUser.vue?vue&type=template&id=1738e644&scoped=true&"
import script from "./AppSidebarUser.vue?vue&type=script&lang=js&"
export * from "./AppSidebarUser.vue?vue&type=script&lang=js&"
import style0 from "./AppSidebarUser.vue?vue&type=style&index=0&id=1738e644&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1738e644",
  null
  
)


import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QList,QItem,QItemSection,QItemLabel,QBtn});
/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1738e644')) {
      api.createRecord('1738e644', component.options)
    } else {
      api.reload('1738e644', component.options)
    }
    module.hot.accept("./AppSidebarUser.vue?vue&type=template&id=1738e644&scoped=true&", function () {
      api.rerender('1738e644', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/app/AppSidebarUser.vue"
export default component.exports