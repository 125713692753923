<template>

    <q-input
        v-bind="{...$attrs, ...$props}"
        outlined
        :label-slot="!!label"
        :stack-label="!!label"
        hide-bottom-space
        :dense="dense"
        :class="{
            'bg-grey-3': disable,
            'text-black': disable,
            'q-field--denser': denser,
        }"
        @input="(value) => $emit('input', value)"
        @change="(event) => $emit('change', event.target.value)"
    >
        <template v-if="!!label" #label>
            <span>{{ label }} <b v-if="required" class="text-negative">*</b></span>
        </template>
        <template #hint>
            {{ hint }}
        </template>
    </q-input>

</template>

<script>
export default {
    name: "BaseInput",
    props: {
        disable: Boolean,
        required: Boolean,
        value: [String, Number],
        label: String,
        hint: String,
        dense: Boolean,
        denser: Boolean,
    },
};
</script>

<style lang="scss">
    .q-field--disabled > .q-field__inner {
        background: #eee !important;
    }
</style>
