<template>
    <q-header class="app-header">
        <q-toolbar class="q-my-xs">
            <q-btn flat round dense icon="menu" @click="minifySidebar = !minifySidebar" />
            <q-space />
            <q-item-section avatar>
                <q-item-label>ID юрлица: {{ user.legal_id }}</q-item-label>
            </q-item-section>
            <q-btn
                type="a"
                :href="`https://www.notion.so/${helpSection}`"
                target="_blank"
                icon="contact_support"
                flat
                round
            />
        </q-toolbar>
    </q-header>
</template>

<script>
import { $authUser } from 'store/auth';

export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },

    effector: {
        user: $authUser,
    },
    data() {
        return {
            minifySidebar: this.value,
        };
    },
    computed: {
        helpSection(){
            return this.$route.meta.help || 'fa4cbb9c7a9540c98e0d1656b797ed5e';
        },
    },
    watch: {
        value(val){
            this.minifySidebar = val;
        },

        minifySidebar(value) {
            this.$emit('input', value);
        },
    },

    created() {
        this.minifySidebar = this.value;
    },
};
</script>
