import { createEffect, restore, createEvent } from 'effector';
import { createCoeffect } from 'utils/effector';
import { LegalsApi } from 'api/LegalsApi';
import { UserApi } from 'api/UserApi';
import { Toast } from 'utils/Toast';
import { updateBank, submitBankRequisites } from './bank';

/* User main */
export const updateLegal = createEvent();
export const getUserLegal = createEffect({ handler: () => UserApi.getLegal() });

export const $userLegal = restore(getUserLegal, { bank_requisites: [], contracts: [] })
    .on(updateLegal, (state, params) => ({ ...state, ...params }))
    .on(updateBank.done, (state, { result }) => {
        const bank_requisites = state.bank_requisites.map((bank) => {
            let value;
            bank.id === result.id ? (value = result) : (value = bank);
            return value;
        });
        return { ...state, bank_requisites };
    })
    .on(submitBankRequisites.done, (state, { result }) => {
        const bank_requisites = [...state.bank_requisites, result];
        return { ...state, bank_requisites };
    });

/* User validation */
export const $isValidLegal = $userLegal.map(
    (legal) => !!legal.name && !!legal.full_name && !!legal.legal_address && !!legal.post_address
);

/* User submit */
export const submitLegal = createCoeffect($userLegal, (legal) => LegalsApi.update(legal));
submitLegal.done.watch(() => {
    getUserLegal();
    Toast.success('Настройки организации успешно обновлены');
});

/* User other */
export const $legalBanks = $userLegal.map(({ bank_requisites }) => bank_requisites);
export const $legalContract = $userLegal.map(({ contracts }) => contracts);
