export const orderService = {
    'C24KO': 1, //Выдача с оплатой
    'C24': 11, //Выдача
    '24KO': 2, //Курьерская доставка с оплатой
    '24': 12, //Курьерская доставка
    'CB24KO': 5, //Возврат с оплатой
    'CB24': 15, //Возврат
    'B24KO': 8, //Возврат курьером с оплатой
    'B24': 18, //Возврат курьером
    'PICKUP': 22, //Забор
    'SHIPPING': 24, //Отгрузка
    'TRANSFER': 25, //Перемещение
    'MAIL': 30, //Корреспонденция
};

export const orderServiceNames = {
    1: 'Выдача КО',
    11: 'Выдача',
    2: 'Курьер КО',
    12: 'Курьер',
    // 5: 'Возврат КО',
    // 15: 'Возврат',
    // 8: 'Возврат курьером КО',
    // 18: 'Возврат курьером',
    // 4: 'Возврат без предварительной заявки',
    // 7: 'С2С Отправление',
    // 17: 'С2С Отправление с оплатой получателем',
    // 21: 'Прием от юрлиц',
    22: 'Забор',
    // 23: 'Возврат',
    24: 'Отгрузка',
    // 25: 'Перемещение',
    // 27: 'Прием от физлиц',
    // 30: 'Корреспонденция',
    // 50: 'Транспортное место',
};

export const orderServiceOptions = Object.keys(orderServiceNames).map(key => {
    return { value: key*1, label: orderServiceNames[key] };
});

export const orderServiceOptionsByArray = (ids) => Object.keys(orderServiceNames)
    .filter((item) => ids.some(id => String(id) === String(item)))
    .map(key => ({ value: key*1, label: orderServiceNames[key] }));

export const getOrderServiceName = (id) => orderServiceNames[id];

export const orderServicesPickup = [
    orderService['C24KO'],
    orderService['C24'],
    orderService['CB24KO'],
    orderService['CB24'],
];

export const orderDeliveryServices = [
    orderService['24KO'],
    orderService['24'],
    orderService['C24KO'],
    orderService['C24'],
];

export const orderServicesCourier = [
    orderService['24KO'],
    orderService['24'],
    orderService['B24KO'],
    orderService['B24'],
];

export const orderServicesPaying = [
    orderService['24KO'],
    orderService['B24KO'],
    orderService['C24KO'],
    orderService['CB24KO'],
];

export const orderServicesReturn = [
    orderService['B24'],
    orderService['B24KO'],
    orderService['CB24'],
    orderService['CB24KO'],
];
