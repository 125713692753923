export const returnReasons = [
    'По договоренности с Клиентом',
    'По просьбе ИМ',
    'Заказ не нужен. Не вовремя доставлен заказ',
    'Не заказывали',
    'Возили 3 раза',
    'Регион',
    'Негабарит',
    'Неверный адрес доставки (заказывали на ПС)',
    'Клиент отказывается предоставить паспортные данные',
    'Иностранный паспорт',
];

export const partialReasons = ['Выбирал', 'Неправильный товар', 'Часть заказа бракована'];

export const postponeReasons = [
    'Заказ не отгружался со склада',
    'Курьер не уложился во временной интервал',
    'Курьер не дозвонился до Клиента (ID2)',
    'Изменение адреса доставки',
    'Рабочий адрес (офис)',
    'По договоренности с клиентом (ID4)',
];

export const dataRejectReasons = {
    1: 'Доставлен другой товар',
    2: 'Не подходит по размеру (мал)',
    3: 'Не подходит по размеру (велик)',
    4: 'Не нравится товар',
    5: 'Не устраивает качество товара',
    6: 'Поврежденный товар',
    7: 'Брак',
    8: 'Товар/цвет отличается от представленного на сайте',
    9: 'Другая причина',
};
