import { createEffect, createEvent, sample, attach } from 'effector';

export const createCoeffect = (store, fn) => {
    const fx = createEffect();
    store.watch((s) => {
        fx.use((arg) => fn(s, arg));
    });

    return fx;
};

export function sampleFrom({ source, fn, target }) {
    const clock = createEvent();
    sample({ source, fn, target, clock });

    return clock;
}

export function getItemFromStoreById(store, api) {
    const fxFetchItem = createEffect({ handler: api });
    const resolveItem = createEffect({ handler: ({ items, id }) => {
        const item = Array.isArray(items) ? items.find((entry) => entry.id === id) : false;
        return item ? item : fxFetchItem(id);
    }});

    return attach({
        source: store,
        effect: resolveItem,
        mapParams: (id, items) => ({ items, id }),
    });
}
