<template>
    <div class="row q-gutter-sm wrap">
        <q-list flat bordered separator class="col bg-white">
            <q-item v-for="item in products" :key="`${item.id}${item.shipping_quantity}`">
                <q-item-section>
                    <q-item-label>{{ item.name }}</q-item-label>
                    <q-item-label class="text-teal">{{ item.vendor_code }}</q-item-label>
                    <q-item-label class="text-grey-8">{{ item.barcode }}</q-item-label>
                </q-item-section>

                <q-item-section side no-wrap>
                    <q-item-label>Отгружено: {{ item.shipping_quantity }}</q-item-label>
                </q-item-section>

                <q-item-section side no-wrap>
                    <div v-for="(cell, j) in item.cells" :key="`${item.id}_${j}`">
                        Кол-во: <q-badge color="teal" align="middle" :label="cell.quantity" />
                    </div>
                </q-item-section>
            </q-item>
        </q-list>

        <q-list dense flat bordered class="col bg-white">
            <q-item>
                <q-item-section side>ПВЗ:</q-item-section>
                <q-item-section>{{ stock.name }}</q-item-section>
            </q-item>
            <q-item>
                <q-item-section side>Регион:</q-item-section>
                <q-item-section>{{ stock.region_name }}</q-item-section>
            </q-item>
            <q-item>
                <q-item-section side>Населённый пункт:</q-item-section>
                <q-item-section>{{ stock.city_name }}</q-item-section>
            </q-item>
            <q-item>
                <q-item-section side>Адрес:</q-item-section>
                <q-item-section>{{ stock.address }}</q-item-section>
            </q-item>
        </q-list>
    </div>
</template>

<script>
export default {
    props: {
        stock: {
            type: Object,
            default: () => ({}),
            required: true,
        },
        products: {
            type: Array,
            default: () => [],
            required: true,
        },
    },
};
</script>
