var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-select",
    _vm._b(
      {
        class: {
          "bg-grey-3": _vm.disable,
          "text-black": _vm.disable,
          "q-field--denser": _vm.denser
        },
        attrs: {
          outlined: "",
          "label-slot": !!_vm.label,
          "stack-label": !!_vm.label,
          dense: _vm.dense
        },
        on: {
          input: function(value) {
            return _vm.$emit("input", value)
          },
          change: function(value) {
            return _vm.$emit("change", value)
          }
        },
        scopedSlots: _vm._u(
          [
            !!_vm.label
              ? {
                  key: "label",
                  fn: function() {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.label) + " "),
                        _vm.required
                          ? _c("b", { staticClass: "text-negative" }, [
                              _vm._v("*")
                            ])
                          : _vm._e()
                      ])
                    ]
                  },
                  proxy: true
                }
              : null,
            {
              key: "no-option",
              fn: function() {
                return [
                  _c(
                    "q-item",
                    [
                      _c("q-item-section", { staticClass: "text-grey" }, [
                        _vm._v(
                          "\n                Ничего не найдено\n            "
                        )
                      ])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      },
      "q-select",
      Object.assign({}, _vm.$attrs, _vm.$props),
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }