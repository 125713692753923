<template>
    <q-dialog v-model="newVersion" seamless position="top">
        <q-card>
            <q-card-section class="row items-center no-wrap">
                <div class="text-weight-bold q-ml-md">Доступна новая версия, перезагрузите страницу!</div>
                <q-btn class="q-ml-xs" flat round icon="update" @click="reloadPage" />
                <q-btn v-close-popup flat round icon="close" />
            </q-card-section>
        </q-card>
    </q-dialog>
</template>

<script>
import { $hasNewVersion } from 'store/siteversion';
export default {
    effector: {
        newVersion: $hasNewVersion,
    },

    methods: {
        reloadPage() {
            window.location.reload();
        },
    },
};
</script>
