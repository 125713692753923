<template>
    <q-select
        :value="value"
        :label="label"
        :label-slot="!!label"
        :stack-label="!!label"
        :options="filteredOptions"
        :option-value="optionValue"
        :option-label="optionLabel"
        use-input
        hide-selected
        fill-input
        input-debounce="300"
        emit-value
        map-options
        :dense="dense"
        outlined
        required
        :disable="disable"
        :rules="rules"
        :loading="loading"
        :class="{
            'q-field--denser': denser,
        }"
        @filter="filter"
        @input="update"
    >
        <template v-if="!!label" #label>
            <span>{{ label }} <b v-if="required" class="text-negative">*</b></span>
        </template>
        <template #no-option>
            <q-item>
                <q-item-section class="text-grey">
                    Ничего не найдено
                </q-item-section>
            </q-item>
        </template>
    </q-select>
</template>

<script>
export default {
    props: {
        value: [Number, String],
        disable: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        label: String,
        options: Array,
        optionValue: {
            type: String,
            default: 'id',
        },
        optionLabel: {
            type: String,
            default: 'name',
        },
        rules: {
            type: Array,
            required: false,
        },
        filterFn: Function,
        dense: Boolean,
        denser: Boolean,
        loading: Boolean,
    },
    data() {
        return {
            query: '',
        };
    },

    computed: {
        filteredOptions() {
            if (this.filterFn) {
                return this.filterFn(this.options);
            }
            return this.options.filter(el => el[this.optionLabel].toLowerCase().indexOf(this.query) > -1);
        },
    },

    methods: {
        filter(value, update) {
            update(() => {
                this.query = value.toLowerCase();
                this.$emit('input-value', value);
            });
        },

        update(value) {
            this.$emit('input', value);
            this.query = '';
        },
    },
};
</script>
