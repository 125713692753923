var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-list",
    { attrs: { separator: "" } },
    [
      _c(
        "q-item",
        [
          _c("q-item-section", { staticClass: "col-6" }, [
            _c("b", [_vm._v("Склад (ПВЗ)")])
          ]),
          _c("q-item-section", [_c("b", [_vm._v("Ячейка")])]),
          _c("q-item-section", [_c("b", [_vm._v("Количество")])])
        ],
        1
      ),
      _vm._l(_vm.cells, function(item, i) {
        return _c(
          "q-item",
          { key: i },
          [
            item.stock.region_name
              ? _c("q-item-section", { staticClass: "col-6" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(item.stock.region_name) +
                        " :: " +
                        _vm._s(item.stock.name) +
                        " @" +
                        _vm._s(item.stock.address)
                    )
                  ])
                ])
              : _vm._e(),
            _c("q-item-section", [_c("span", [_vm._v(_vm._s(item.barcode))])]),
            _c("q-item-section", [_c("span", [_vm._v(_vm._s(item.quantity))])])
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }