import { createEffect, createEvent, createStore } from 'effector';
import axios from 'axios';

const getMetaLastUpdate = (elem) => elem.querySelector('meta[name="last-update"]').content;
const alertingNewVersion = createEvent();

export const getActualVersion = createEffect(() => axios.get('/'));

export const $hasNewVersion = createStore(false).on(alertingNewVersion, () => true);

getActualVersion.finally.watch(({ result }) => {
    const respHtml = new DOMParser().parseFromString(result.request.responseText, 'text/html');
    if (getMetaLastUpdate(respHtml) !== getMetaLastUpdate(document)) {
        alertingNewVersion();
    }
    setTimeout(() => getActualVersion(), 128000);
});
