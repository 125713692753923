import { createEffect, restore, createEvent } from 'effector';
import { UsersApi } from 'api/Admin/UsersApi';
import { Toast } from 'utils/Toast';
import { createCoeffect } from 'utils/effector';

export const getUser = createEffect({ handler: (id, params) => UsersApi.get(id, params) });
export const deleteUser = createEffect({ handler: (id) => UsersApi.delete(id) });
export const restoreUser = createEffect({  });

export const updateUser = createEvent();
export const resetUser = createEvent();

export const $user = restore(getUser, { permissions: [], stocks: [] })
    .on(updateUser, (state, params) => ({ ...state, ...params }))
    .reset(getUser, resetUser);

export const $contacts = $user.map((user) => user.contacts || []);

export const submitUser = createCoeffect($user, (user) => (user.id ? UsersApi.update(user) : UsersApi.create(user)));

submitUser.done.watch(({ result }) =>
    Toast.success(`Пользователь ${result.login} ${$user.getState().id ? 'обновлен' : 'создан'}`)
);

deleteUser.done.watch(({ params }) => Toast.success(`Пользователь ${params.login} удален`));

restoreUser.done.watch(({ params }) => Toast.success(`Пользователь ${params.login} восстановлен`));
