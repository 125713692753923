var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-header",
    { staticClass: "app-header" },
    [
      _c(
        "q-toolbar",
        { staticClass: "q-my-xs" },
        [
          _c("q-btn", {
            attrs: { flat: "", round: "", dense: "", icon: "menu" },
            on: {
              click: function($event) {
                _vm.minifySidebar = !_vm.minifySidebar
              }
            }
          }),
          _c("q-space"),
          _c(
            "q-item-section",
            { attrs: { avatar: "" } },
            [
              _c("q-item-label", [
                _vm._v("ID юрлица: " + _vm._s(_vm.user.legal_id))
              ])
            ],
            1
          ),
          _c("q-btn", {
            attrs: {
              type: "a",
              href: "https://www.notion.so/" + _vm.helpSection,
              target: "_blank",
              icon: "contact_support",
              flat: "",
              round: ""
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }