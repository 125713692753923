var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-form",
    [
      _c(
        "q-card",
        { staticClass: "bg-white", attrs: { flat: "" } },
        [
          _c(
            "q-card-section",
            { staticClass: "row q-pa-none" },
            [_vm._t("form-body")],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }