var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row q-gutter-sm wrap" },
    [
      _c(
        "q-list",
        {
          staticClass: "col bg-white",
          attrs: { flat: "", bordered: "", separator: "" }
        },
        _vm._l(_vm.products, function(item) {
          return _c(
            "q-item",
            { key: "" + item.id + item.shipping_quantity },
            [
              _c(
                "q-item-section",
                [
                  _c("q-item-label", [_vm._v(_vm._s(item.name))]),
                  _c("q-item-label", { staticClass: "text-teal" }, [
                    _vm._v(_vm._s(item.vendor_code))
                  ]),
                  _c("q-item-label", { staticClass: "text-grey-8" }, [
                    _vm._v(_vm._s(item.barcode))
                  ])
                ],
                1
              ),
              _c(
                "q-item-section",
                { attrs: { side: "", "no-wrap": "" } },
                [
                  _c("q-item-label", [
                    _vm._v("Отгружено: " + _vm._s(item.shipping_quantity))
                  ])
                ],
                1
              ),
              _c(
                "q-item-section",
                { attrs: { side: "", "no-wrap": "" } },
                _vm._l(item.cells, function(cell, j) {
                  return _c(
                    "div",
                    { key: item.id + "_" + j },
                    [
                      _vm._v("\n                    Кол-во: "),
                      _c("q-badge", {
                        attrs: {
                          color: "teal",
                          align: "middle",
                          label: cell.quantity
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            ],
            1
          )
        }),
        1
      ),
      _c(
        "q-list",
        {
          staticClass: "col bg-white",
          attrs: { dense: "", flat: "", bordered: "" }
        },
        [
          _c(
            "q-item",
            [
              _c("q-item-section", { attrs: { side: "" } }, [_vm._v("ПВЗ:")]),
              _c("q-item-section", [_vm._v(_vm._s(_vm.stock.name))])
            ],
            1
          ),
          _c(
            "q-item",
            [
              _c("q-item-section", { attrs: { side: "" } }, [
                _vm._v("Регион:")
              ]),
              _c("q-item-section", [_vm._v(_vm._s(_vm.stock.region_name))])
            ],
            1
          ),
          _c(
            "q-item",
            [
              _c("q-item-section", { attrs: { side: "" } }, [
                _vm._v("Населённый пункт:")
              ]),
              _c("q-item-section", [_vm._v(_vm._s(_vm.stock.city_name))])
            ],
            1
          ),
          _c(
            "q-item",
            [
              _c("q-item-section", { attrs: { side: "" } }, [_vm._v("Адрес:")]),
              _c("q-item-section", [_vm._v(_vm._s(_vm.stock.address))])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }