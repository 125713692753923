export const routesOrders = [
    {
        name: 'Orders',
        path: '/orders',
        component: () => import('pages/Orders/Index.vue'),
        meta: {
            help: 'bedc51063ae14a0a86dfb2fd08e628ef',
        },
    },
    {
        name: 'OrdersCreate',
        path: '/orders/create',
        component: () => import('pages/Orders/Create.vue'),
    },
    {
        name: 'OrdersExcel',
        path: '/orders/excel',
        component: () => import('pages/Orders/Excel.vue'),
        meta: {
            help: 'Excel-c1bbba8fc73b4c7fa280d2d48211f0c1',
        },
    },
    // {
    //     name: 'OrdersEdit',
    //     path: '/couriers/:id',
    //     props: true,
    //     component: () => import('pages/Orders/Form.vue'),
    // },
];
