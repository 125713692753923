<template>
    <q-layout view="lHh LpR fff">
        <app-header v-model="minify" />

        <app-sidebar v-model="minify" />

        <q-page-container>
            <q-page class="bg-layout">
                <transition name="fade" mode="out-in">
                    <router-view />
                </transition>
            </q-page>
        </q-page-container>

        <app-footer />
        <app-notify />
    </q-layout>
</template>

<script>
import AppSidebar from 'components/app/AppSidebar';
import AppHeader from 'components/app/AppHeader';
import AppFooter from 'components/app/AppFooter';
import AppNotify from 'components/app/AppNotify';

const MINIFY = localStorage.getItem('minifySidebar');

export default {
    name: 'MainLayout',

    components: {
        AppSidebar,
        AppHeader,
        AppFooter,
        AppNotify,
    },
    data() {
        return {
            minify: MINIFY === 'true',
        };
    },
};
</script>

<style>
    .fade-enter-active, .fade-leave-active {
        transition: all .3s ease-in-out;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>
