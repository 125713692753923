import axios from 'axios';
import { Toast } from 'utils/Toast';
import { redirectToAuth } from 'utils/helpers';
import { resetValidationErrors, setValidationErrors } from 'store/errors';

const domain = process.env.API_DOMAIN;

export const connection = axios.create({
    withCredentials: true,
    baseURL: domain,
});

connection.interceptors.response.use(
    (response) => {
        const { data } = response;
        if (data.success === false) {

            data.errors.forEach(err => Toast.error(err));
            return Promise.reject();
        }

        if (data.message) {
            Toast.success(data.message);
        }

        return response;
    },
    ({ response }) => {
        if (response.status === 401) {
            redirectToAuth();
        }
        resetValidationErrors();

        if (response.data.success === false) {
            if (response.data.data.some(({ error }) => error === true)) {
                const errors = Object.fromEntries(
                    response.data.data.filter(({ error }) => error === true)
                        .map((el) =>
                            Object.entries(el)
                                .filter(([, value]) => value !== null && typeof value === 'object')
                                .map(([key, value]) => [key, value.errors])
                        )
                        .flat()
                );

                setValidationErrors(errors);
            } else if (response.data.errors.validation) {
                setValidationErrors(response.data.errors.validation);
            } else {
                Object.values(response.data.errors).forEach(err => Toast.error(err));
            }
        }
        // Toast.error(message);
        return Promise.reject(response);
    }
);
