import { render, staticRenderFns } from "./ExpandingList.vue?vue&type=template&id=d147e674&scoped=true&"
import script from "./ExpandingList.vue?vue&type=script&lang=js&"
export * from "./ExpandingList.vue?vue&type=script&lang=js&"
import style0 from "./ExpandingList.vue?vue&type=style&index=0&id=d147e674&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d147e674",
  null
  
)


import QCard from 'quasar/src/components/card/QCard.js';
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QMarkupTable,QCardActions,QBtn});
/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d147e674')) {
      api.createRecord('d147e674', component.options)
    } else {
      api.reload('d147e674', component.options)
    }
    module.hot.accept("./ExpandingList.vue?vue&type=template&id=d147e674&scoped=true&", function () {
      api.rerender('d147e674', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ExpandingList.vue"
export default component.exports