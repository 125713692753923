var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.hasAccess
        ? _c(
            "div",
            { class: { "q-px-sm": _vm.spaced } },
            [_vm._t("default")],
            2
          )
        : _c("div", [_c("error403")], 1),
      _c(
        "q-inner-loading",
        { attrs: { showing: _vm.loading } },
        [
          _c("q-spinner-gears", {
            staticClass: "primary",
            attrs: { size: "md" }
          }),
          _c("span", { staticClass: "loader-text text-primary" }, [
            _vm._v("Секундочку...")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }