/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/

import lang from 'quasar/lang/ru'


import Vue from 'vue'

import {Quasar,QAvatar,QBadge,QBanner,QBreadcrumbs,QBreadcrumbsEl,QBtn,QBtnDropdown,QBtnToggle,QCard,QCardSection,QCardActions,QCheckbox,QChip,QDialog,QDrawer,QExpansionItem,QField,QFile,QFooter,QForm,QHeader,QIcon,QImg,QInnerLoading,QInput,QItem,QItemLabel,QItemSection,QMarkupTable,QMenu,QLayout,QList,QOptionGroup,QPage,QPageContainer,QPageSticky,QPagination,QRouteTab,QScrollArea,QSelect,QSeparator,QSkeleton,QSpace,QSpinnerGears,QSpinnerIos,QSpinnerOval,QSplitter,QStep,QStepper,QStepperNavigation,QTab,QTabs,QTabPanels,QTabPanel,QTable,QTr,QTh,QTd,QToolbar,QToolbarTitle,QTooltip,QToggle,Ripple,ClosePopup,Notify,Dialog} from 'quasar'


Vue.use(Quasar, { config: {},lang: lang,components: {QAvatar,QBadge,QBanner,QBreadcrumbs,QBreadcrumbsEl,QBtn,QBtnDropdown,QBtnToggle,QCard,QCardSection,QCardActions,QCheckbox,QChip,QDialog,QDrawer,QExpansionItem,QField,QFile,QFooter,QForm,QHeader,QIcon,QImg,QInnerLoading,QInput,QItem,QItemLabel,QItemSection,QMarkupTable,QMenu,QLayout,QList,QOptionGroup,QPage,QPageContainer,QPageSticky,QPagination,QRouteTab,QScrollArea,QSelect,QSeparator,QSkeleton,QSpace,QSpinnerGears,QSpinnerIos,QSpinnerOval,QSplitter,QStep,QStepper,QStepperNavigation,QTab,QTabs,QTabPanels,QTabPanel,QTable,QTr,QTh,QTd,QToolbar,QToolbarTitle,QTooltip,QToggle},directives: {Ripple,ClosePopup},plugins: {Notify,Dialog} })
