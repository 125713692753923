import { render, staticRenderFns } from "./BaseSelectFilter.vue?vue&type=template&id=2177e6b5&"
import script from "./BaseSelectFilter.vue?vue&type=script&lang=js&"
export * from "./BaseSelectFilter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


import QSelect from 'quasar/src/components/select/QSelect.js';
import QField from 'quasar/src/components/field/QField.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QSelect,QField,QItem,QItemSection});
/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2177e6b5')) {
      api.createRecord('2177e6b5', component.options)
    } else {
      api.reload('2177e6b5', component.options)
    }
    module.hot.accept("./BaseSelectFilter.vue?vue&type=template&id=2177e6b5&", function () {
      api.rerender('2177e6b5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/BaseSelectFilter.vue"
export default component.exports