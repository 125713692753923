<template>
    <div class="row q-col-gutter-md">
        <div
            v-for="(option, j) in options"
            :key="`option_${option.name}_${j}`"
            :class="option.colClass || 'col'"
        >
            <h6 v-if="option.type === 'label'" class="text-subtitle2 q-ma-none">{{ option.label }}</h6>

            <q-checkbox
                v-else-if="option.type === 'checkbox'"
                v-model="item[option.name]"
                :label="option.label"
                :readonly="!!item.readonly || !!option.readonly"
            />

            <base-select
                v-else-if="option.type === 'select'"
                v-model="item[option.name]"
                :label="option.label"
                :options="option.options"
                emit-value
                map-options
                dense
                stacked
                :required="option.required"
                :readonly="!!item.readonly || !!option.readonly"
                @input="update"
            />

            <base-input
                v-else
                v-model="item[option.name]"
                v-bind="option"
                dense
                stacked
                :readonly="!!item.readonly || !!option.readonly"
                @input="update()"
            />

        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: Object,
        options: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            item: { ...this.value },
        };
    },
    methods: {
        update() {
            this.$emit('input', this.item);
        },
    },
};
</script>
