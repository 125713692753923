<template>
    <q-drawer
        :value="true"
        :mini="minifySidebar"
        :width="250"
        side="left"
        class="app-sidebar"
        content-class="bg-blue-grey-10"
        show-if-above
        @mouseover="minifySidebar = !value ? value : !value"
        @mouseout="minifySidebar = value"
    >
        <div class="sidebar-menu">
            <q-list>
                <template v-for="(menuItem, menuIndex) in menu">
                    <q-expansion-item
                        v-if="menuItem.items && (!('allow' in menuItem) || menuItem.allow)"
                        :key="menuIndex"
                        header-class="expand-header"
                        active-class="bg-blue-grey-3"
                        expand-separator
                        group="menuGroup"
                        :icon="menuItem.icon"
                        :label="menuItem.name"
                        :default-opened="hasActiveRouteChild(menuItem.items)"
                    >
                        <template v-for="(item, itemIndex) in menuItem.items">
                            <q-item
                                v-if="!('allow' in item) || item.allow"
                                :key="itemIndex"
                                :to="{ name: item.routeName }"
                            >
                                <q-item-section v-if="'icon' in menuItem" avatar>
                                    <q-icon :name="item.icon" />
                                </q-item-section>
                                <q-item-section>{{ item.name }}</q-item-section>
                            </q-item>
                        </template>
                    </q-expansion-item>
                    <q-item
                        v-else-if="!('allow' in menuItem) || menuItem.allow"
                        :key="menuIndex"
                        :to="{ name: menuItem.routeName || menuItem.items[0].routeName }"
                    >
                        <q-item-section v-if="'icon' in menuItem" avatar>
                            <q-icon :name="menuItem.icon" />
                        </q-item-section>

                        <q-item-section>{{ menuItem.name }}</q-item-section>
                    </q-item>
                </template>
            </q-list>
        </div>
        <app-sidebar-user class="sidebar-toolbar absolute-top" />
    </q-drawer>
</template>

<script>
import AppSidebarUser from 'components/app/AppSidebarUser';
import { $menu } from 'store/menu';

export default {
    components: {
        AppSidebarUser,
    },

    effector: {
        menu: $menu,
    },

    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            minifySidebar: this.value,
        };
    },

    watch: {
        value(value) {
            localStorage.setItem('minifySidebar', value);
            this.minifySidebar = value;
            this.$emit('input', value);
        },
    },
    methods: {
        hasActiveRouteChild(items) {
            return items.some((el) => el.routeName === this.$route.name);
        },
    },
};
</script>

<style lang="scss" scope>
.app-sidebar {
    .q-expansion-item--expanded {
        .expand-header {
            font-weight: bold;
        }
    }

    .q-expansion-item__container {
        a {
            padding-left: 72px;
            border-top: 1px solid $grey-3;
        }
    }

    .sidebar-menu {
        margin-top: 60px;
    }
    .q-link {
        color: #ffffff;

        &.q-router-link--exact-active {
            background: $grey-1;
            color: $primary;
        }
    }
}
</style>
