<template>
    <q-card flat class="my-card bg-grey-1">
        <q-card-section class="row">
            <div class="col text-h6">{{ title }}</div>
            <div class="col text-right headerButtons">
                <slot name="headerButtons" />
                <q-btn v-if="$slots.headerMenu" color="grey-7" round flat icon="more_vert">
                    <q-menu cover auto-close>
                        <slot name="headerMenu" />
                    </q-menu>
                </q-btn>
            </div>
        </q-card-section>
        <q-separator />

        <template v-if="$slots.headerForm">
            <q-card-section>
                <slot name="headerForm" />
            </q-card-section>
            <q-separator />
        </template>

        <q-table
            :data="tableData"
            :columns="columns"
            :row-key="rowKey || 'id'"
            :visible-columns="visibleColumns"
            :pagination.sync="localPagination"
            :loading="loading"
            :rows-per-page-options="rowsPerPageOptions"
            @request="({ pagination }) => $emit('page-change', pagination)"
        >
            <template v-if="visibleColumns" #top-right>
                <q-btn-dropdown size="sm" color="grey-6" unelevated outline icon="table_chart">
                    <div class="row no-wrap q-pa-md">
                        <div class="column">
                            <q-toggle
                                v-for="(col, i) in togglableColumns"
                                :key="`toggle_${i}`"
                                v-model="visibleCols"
                                :val="col.name"
                                :label="col.label"
                                size="xs"
                                @input="updateVisibleColumns()"
                            />
                        </div>
                    </div>
                </q-btn-dropdown>
            </template>

            <template #header="props">
                <q-tr :props="props">
                    <q-th v-if="expandKey" auto-width />
                    <q-th v-for="col in props.cols.filter((el) => el.name != expandKey)" :key="col.name" :props="props">
                        {{ col.label }}
                    </q-th>
                </q-tr>
            </template>

            <template #body="props">
                <q-tr :props="props">
                    <q-td v-if="expandKey" :key="`m_${props.row.id}`" auto-width>
                        <q-btn
                            size="sm"
                            color="accent"
                            round
                            dense
                            unelevated
                            :icon="props.expand ? 'remove' : 'add'"
                            @click="props.expand = !props.expand"
                        />
                    </q-td>
                    <q-td v-for="col in props.cols.filter((el) => el.name != expandKey)" :key="col.name" :props="props">
                        <div v-if="col.name == 'actions'" class="q-gutter-x-sm">
                            <div :is="actionWidget" v-if="!!actionWidget" v-bind="props.row" />
                            <q-btn
                                v-if="editable"
                                icon="edit"
                                size="sm"
                                outline
                                color="indigo"
                                @click="$emit('edit', props.key)"
                            />
                            <q-btn
                                v-if="deletable"
                                icon="delete"
                                size="sm"
                                outline
                                color="red"
                                @click="handleDelete(props.key, props.row.name)"
                            />
                        </div>

                        <div v-else>
                            <q-badge v-if="col.badge && col.value !== '-'" :color="col.badge" :label="col.value" />

                            <span v-else>{{ col.value }}</span>
                        </div>
                    </q-td>
                </q-tr>
                <q-tr
                    v-show="props.expand"
                    :key="`e_${props.row.id}`"
                    :props="props"
                    class="q-virtual-scroll--with-prev"
                >
                    <q-td colspan="100%" class="bg-amber-2">
                        <div
                            :is="expandedWidget"
                            v-if="!!expandedWidget && expandKey"
                            v-bind="props.row"
                            class="text-left"
                        />
                    </q-td>
                </q-tr>
            </template>
        </q-table>

        <slot name="appends" />
    </q-card>
</template>

<script>
import BarcodeComponent from 'components/BarcodeComponent';

export default {
    components: {
        barcode: BarcodeComponent,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        tableData: {
            type: Array,
            required: true,
        },
        columns: {
            type: Array,
            required: true,
        },
        visibleColumns: {
            type: Array,
        },
        pagination: {
            type: Object,
            default: () => ({
                rowsPerPage: 20,
                page: 1,
            }),
        },
        rowsPerPageOptions: {
            type: Array,
            default: () => ([10, 20, 50, 100, 300]),
        },
        loading: {
            type: Boolean,
            default: false,
        },
        rowKey: String,
        editable: Boolean,
        deletable: Boolean,
        expandKey: String,
        expandedWidget: String,
        actionWidget: String,
    },
    data() {
        return {
            visibleCols: this.visibleColumns,
            localPagination: this.pagination,
        };
    },
    computed: {
        togglableColumns() {
            return this.columns.filter((el) => typeof el.togglable === 'undefined' || !!el.togglable);
        },
    },

    watch: {
        pagination: {
            handler(val) {
                this.localPagination = val;
            },
            deep: true,
        },
    },

    methods: {
        updateVisibleColumns() {
            this.$emit('update:visibleColumns', this.visibleCols);
        },

        handleDelete(key, name) {
            confirm(() => this.$emit('delete', key), `Удалить «${name}»?`);
        },
    },
};
</script>

<style lang="scss">
.headerButtons {
    & > * {
        margin-left: 1em;
    }
}
</style>
