var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-btn",
    _vm._b(
      {
        attrs: { "no-wrap": "", icon: "delete", outline: "", color: "red-10" },
        on: {
          click: function($event) {
            return _vm.$emit("click")
          }
        }
      },
      "q-btn",
      Object.assign({}, _vm.$attrs, _vm.$props),
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }