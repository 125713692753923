var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-dialog",
    {
      attrs: { seamless: "", position: "top" },
      model: {
        value: _vm.newVersion,
        callback: function($$v) {
          _vm.newVersion = $$v
        },
        expression: "newVersion"
      }
    },
    [
      _c(
        "q-card",
        [
          _c(
            "q-card-section",
            { staticClass: "row items-center no-wrap" },
            [
              _c("div", { staticClass: "text-weight-bold q-ml-md" }, [
                _vm._v("Доступна новая версия, перезагрузите страницу!")
              ]),
              _c("q-btn", {
                staticClass: "q-ml-xs",
                attrs: { flat: "", round: "", icon: "update" },
                on: { click: _vm.reloadPage }
              }),
              _c("q-btn", {
                directives: [{ name: "close-popup", rawName: "v-close-popup" }],
                attrs: { flat: "", round: "", icon: "close" }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }