import { routesOrders } from './routesOrders';
import { routesProduct } from './routesProduct';
import { routesShipping } from './routesShipping';
import { routesResidues } from './routesResidues';
import { routesReports } from './routesReports';
import { routesReturns } from './routesReturns';
import { routesStock } from './routesStock';
import { routesLegal } from './routesLegal';

const routes = [
    ...routesOrders,
    ...routesProduct,
    ...routesShipping,
    ...routesResidues,
    ...routesReports,
    ...routesReturns,
    ...routesStock,
    ...routesLegal,
    {
        name: 'Dashboard',
        path: '/',
        component: () => import('pages/Index.vue'),
        props: {
            exact: true,
        },
    },
];

// Always leave this as last one
routes.push({
    path: '*',
    component: () => import('pages/Error404.vue'),
});

export default routes;
