var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("q-select", {
    class: {
      "q-field--denser": _vm.denser
    },
    attrs: {
      value: _vm.value,
      label: _vm.label,
      "label-slot": !!_vm.label,
      "stack-label": !!_vm.label,
      options: _vm.filteredOptions,
      "option-value": _vm.optionValue,
      "option-label": _vm.optionLabel,
      "use-input": "",
      "hide-selected": "",
      "fill-input": "",
      "input-debounce": "300",
      "emit-value": "",
      "map-options": "",
      dense: _vm.dense,
      outlined: "",
      required: "",
      disable: _vm.disable,
      rules: _vm.rules,
      loading: _vm.loading
    },
    on: { filter: _vm.filter, input: _vm.update },
    scopedSlots: _vm._u(
      [
        !!_vm.label
          ? {
              key: "label",
              fn: function() {
                return [
                  _c("span", [
                    _vm._v(_vm._s(_vm.label) + " "),
                    _vm.required
                      ? _c("b", { staticClass: "text-negative" }, [_vm._v("*")])
                      : _vm._e()
                  ])
                ]
              },
              proxy: true
            }
          : null,
        {
          key: "no-option",
          fn: function() {
            return [
              _c(
                "q-item",
                [
                  _c("q-item-section", { staticClass: "text-grey" }, [
                    _vm._v("\n                Ничего не найдено\n            ")
                  ])
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }