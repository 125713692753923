var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "q-gutter-x-xs q-pr-md no-wrap" },
    [
      _c(
        "q-btn-dropdown",
        {
          attrs: {
            outline: "",
            size: "sm",
            "no-caps": "",
            "auto-close": "",
            unelevated: "",
            "menu-anchor": "bottom left",
            "menu-self": "top left",
            color: "primary",
            label: "Печать"
          }
        },
        [
          _c(
            "q-list",
            [
              _vm.barcode
                ? _c(
                    "q-item",
                    {
                      attrs: { clickable: "" },
                      on: {
                        click: function($event) {
                          return _vm.$htmlToPaper(
                            "print_" + _vm.id + "_" + _vm.barcode
                          )
                        }
                      }
                    },
                    [
                      _c(
                        "q-item-section",
                        [
                          _c("barcode", {
                            attrs: {
                              id: "print_" + _vm.id + "_" + _vm.barcode,
                              position: "top",
                              label: "DP.express",
                              barcode: _vm.barcode,
                              number: _vm.number,
                              "with-logo": "",
                              hidden: ""
                            }
                          }),
                          _c("q-item-label", [_vm._v("Штрихкод DP.express")])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.media && _vm.media.length
                ? _vm._l(_vm.media, function(item, i) {
                    return _c(
                      "q-item",
                      {
                        key: "media_" + _vm.id + "_" + i,
                        attrs: {
                          tag: "a",
                          href: item.url,
                          clickable: "",
                          target: "_blank"
                        }
                      },
                      [
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.collectionNameTrans(item.collection_name)
                                )
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                : _vm._e(),
              _vm.sharedMedia && _vm.sharedMedia.length
                ? _vm._l(_vm.sharedMedia, function(item, i) {
                    return _c(
                      "q-item",
                      {
                        key: "sharedMedia_" + _vm.id + "_" + i,
                        attrs: {
                          tag: "a",
                          href: item.doc.url,
                          clickable: "",
                          target: "_blank"
                        }
                      },
                      [
                        _c(
                          "q-item-section",
                          [
                            _c("q-item-label", [
                              _vm._v(
                                _vm._s(
                                  _vm.collectionNameTrans(
                                    item.doc.collection_name
                                  )
                                )
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }