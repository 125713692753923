<template>
    <div>

        <div v-if="hasAccess" :class="{'q-px-sm': spaced}">
            <slot />
        </div>
        <div v-else>
            <error403 />
        </div>
        <q-inner-loading :showing="loading">
            <q-spinner-gears size="md" class="primary" />
            <span class="loader-text text-primary">Секундочку...</span>
        </q-inner-loading>
    </div>
</template>

<script>
import Error403 from 'pages/Error403';

export default {
    components: { Error403 },

    effector: {
        //
    },

    props: {
        breadcrumb: Boolean,
        loading: Boolean,
        spaced: Boolean,
        inset: Boolean,
        hasAccess: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style lang="styl" scoped>
.index-top {
    z-index: 10;
}
</style>
