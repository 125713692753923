<template>
    <q-btn
        v-bind="{...$attrs, ...$props}"
        no-wrap
        icon="delete"
        outline
        color="red-10"
        @click="$emit('click')"
    />
</template>

<script>
export default {};
</script>
