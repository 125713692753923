import { render, staticRenderFns } from "./MainLayout.vue?vue&type=template&id=7e0bdb39&"
import script from "./MainLayout.vue?vue&type=script&lang=js&"
export * from "./MainLayout.vue?vue&type=script&lang=js&"
import style0 from "./MainLayout.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


import QLayout from 'quasar/src/components/layout/QLayout.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QLayout,QPageContainer,QPage});
/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7e0bdb39')) {
      api.createRecord('7e0bdb39', component.options)
    } else {
      api.reload('7e0bdb39', component.options)
    }
    module.hot.accept("./MainLayout.vue?vue&type=template&id=7e0bdb39&", function () {
      api.rerender('7e0bdb39', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/layouts/MainLayout.vue"
export default component.exports