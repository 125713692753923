import { orderServicesCourier, orderServicesPaying, orderServicesPickup } from '..';

export const orderStage = {
    UNDEFINED: null,
    IN_STORE: 1,
    ISSUED: 3,
    CLOSED: 5,
};

const orderStageNames = {
    null: '-',
    1: 'Принят на склад',
    3: 'Оформлен',
    5: 'Закрыт и учтен',
};

export const getOrderStageName = (id) => orderStageNames[id];

export const orderStatus = {
    NOT_ACCEPTED: null,
    IN_STORE: 0,
    DELIVERED: 1,
    WRONG_DELIVERY: 2,
    REJECTION: 3,
    POSTPONE: 4,
    PARTIAL_DELIVERY: 5,
    EXCHANGE: 6,
    OVERDUED: 7,
};

const orderStatusNames = {
    null: '-',
    0: 'Принят',
    1: 'Доставлен',
    2: 'Ошибочная доставка',
    3: 'Отказ',
    4: 'Перенос',
    5: 'Частичный отказ',
    6: 'Обмен',
    7: 'Отмена по сроку',
};

export const getOrderStatusName = (id) => orderStatusNames[id];

export const orderState = {
    UNDEFINED: null,
    CREATED: 100,
    ASSIGNED_TO_CARRIER: 110,
    UPLOADED_TO_CARRIER: 120,
    PENDING_SUPPLY: 125,
    PENDING_PICKUP: 130,
    TRANSFERRED_TO_CARRIER: 150,
    NOT_ACCEPTED: 200,
    IN_STORE: 201,
    COURIER: 202,
    DELIVERED: 203,
    FILIAL: 210,
};

const orderStateNames = {
    null: '-',
    100: 'Создан',
    110: 'Назначен перевозчик',
    120: 'Данные переданы перевозчику',
    125: 'Ожидает привоза на ПВЗ',
    130: 'Ожидает забора',
    150: 'На складе перевозчика',
    200: 'Не принят',
    201: 'На Складе',
    202: 'У Курьера',
    203: 'Доставлен',
    210: 'Филиал',
};

export const getOrderStateName = (id) => orderStateNames[id];

export const orderActions = {
    ISSUE: 'issueOrder',
    PARTIAL_ISSUE_DATA: 'partialIssueOrderData',
    PARTIAL_ISSUE_NO_DATA: 'partialIssueOrderNoData',
    COMPLETE_REFUSE: 'completeRefuse',
    RETURN_ACCEPTED: 'returnAccepted',
    RETURN_REJECTED: 'returnRejected',
    RETURN_TO_PICKUP: 'returnToPickup',
    POSTPONED: 'postponed',
    WRONG_DELIVERY: 'wrongDelivery',
};

export const operations = {
    state: 'Состояние',
    status: 'Статус',
    stage: 'Этап',
    parcel_status: 'Статус КС',
};

/**
 * Set of required order fields by service
 *
 * @param {int} service
 *
 * @returns {Set}
 */
export const requiredFields = (service) => {
    const fields = new Set([
        'service',
        'origin',
        'destination',
        'number',
        'client_name',
        'client_phone',
        'start_point',
        'volume',
        'weight',
        'width',
        'height',
        'length',
    ]);

    switch (true) {
        case orderServicesCourier.includes(service):
            ['address', 'delivery_date', 'delivery_time'].forEach(fields.add, fields);
            break;

        case orderServicesPickup.includes(service):
            ['final_point', 'expiry_date'].forEach(fields.add, fields);
            break;
    }

    if (orderServicesPaying.includes(service)) {
        ['amount', 'declared_price'].forEach(fields.add, fields);
    }

    return fields;
};
