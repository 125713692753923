import { VueEffector } from 'effector-vue/compat';
import { Dialog, colors } from 'quasar';

import VueHtmlToPaper from 'vue-html-to-paper';
import VueBarcode from 'vue-barcode';

import Echo from 'laravel-echo';

import { getUserLegal } from 'store/user';
import { getCurrentUser } from 'store/auth';
import { getActualVersion } from 'store/siteversion';

import AppContent from 'components/app/AppContent';
import LoadingWrapper from 'components/LoadingWrapper';
import BaseInput from 'components/BaseInput';
import BaseSelect from 'components/BaseSelect';
import BaseInputUpdate from 'components/BaseInputUpdate';
import BaseSelectFilter from 'components/BaseSelectFilter';
import IndexTable from 'components/layouts/IndexTable';
import ExpandingList from 'components/ExpandingList';
import EditForm from 'components/layouts/EditForm';
import InputsGroup from 'components/Inputs/InputsGroup';
import DialogModal from 'components/layouts/DialogModal';
import OrderActionsWidget from 'components/widgets/OrderActionsWidget';
import OrderDetailsWidget from 'components/widgets/OrderDetailsWidget';
import ShippingsEntities from 'components/widgets/ShippingsEntities';
import ResiduesEntityWidget from 'components/widgets/ResiduesEntityWidget';
import SalesReportWidget from 'components/widgets/SalesReportWidget';

import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { onlineChatOptions } from 'utils/onlineChat';

export default async ({ Vue }) => {
    /**
     * Extend quasar
     */
    colors.setBrand('inactive', '#444');
    colors.setBrand('layout', '#444');

    /**
     * register effector bindings
     */
    Vue.use(VueEffector);
    Vue.use(VueHtmlToPaper);

    /**
     * Public components
     */
    Vue.component('v-barcode', VueBarcode);

    Vue.component('loading-wrapper', LoadingWrapper);
    Vue.component('base-input', BaseInput);
    Vue.component('base-select', BaseSelect);
    Vue.component('base-input-update', BaseInputUpdate);
    Vue.component('base-select-filter', BaseSelectFilter);
    Vue.component('app-content', AppContent);
    Vue.component('index-table', IndexTable);
    Vue.component('expanding-list', ExpandingList);
    Vue.component('edit-form', EditForm);
    Vue.component('inputs-group', InputsGroup);
    Vue.component('dialog-modal', DialogModal);
    Vue.component('OrderActionsWidget', OrderActionsWidget);
    Vue.component('OrderDetailsWidget', OrderDetailsWidget);
    Vue.component('ShippingsEntitiesWidget', ShippingsEntities);
    Vue.component('ResiduesEntityWidget', ResiduesEntityWidget);
    Vue.component('SalesReportWidget', SalesReportWidget);

    /**
     * redeclare standart confirm dialog to quasar one
     */
    window.confirm = (callback, message, title = 'Подтвердите действие') => {
        Dialog.create({
            title: title,
            message: message,
            ok: { flat: true },
            cancel: { flat: true },
            persistent: true,
        }).onOk(() => {
            callback();
        });
    };
    window.prompt = (callback, message, title = 'Подтвердите действие') => {
        Dialog.create({
            title,
            message,
            prompt: {
                model: '',
                type: 'text', // optional
            },
            ok: { flat: true },
            cancel: { flat: true },
            persistent: true,
        }).onOk((data) => {
            callback(data);
        });
    };
    window.promptOptions = (callback, options, message, title = 'Подтвердите действие') => {
        Dialog.create({
            title,
            message,
            options: {
                type: 'radio',
                ...options,
            },
            ok: { flat: true },
            cancel: { flat: true },
            persistent: true,
        }).onOk((data) => {
            callback(data);
        });
    };

    /**
     * Extend vue
     */
    Vue.prototype.$log = console.log.bind(console);
    Vue.prototype.$confirm = window.confirm;
    Vue.prototype.$prompt = window.prompt;
    Vue.prototype.$promptOptions = window.promptOptions;

    /**
     * Authorization
     */
    getCurrentUser.done.watch(({ result }) => {
        getUserLegal();

        window.Pusher = require('pusher-js');
        window.Echo = new Echo({
            broadcaster: process.env.BROADCAST_DRIVER,
            key: process.env.PUSHER_APP_KEY,
            httpHost: process.env.BROADCAST_HOST,
            wsHost: process.env.BROADCAST_HOST,
            wssPort: 443,
            forceTLS: true,
            encrypted: true,
            disableStats: true,
            authEndpoint: `//${process.env.BROADCAST_HOST}/broadcasting/auth`,
            auth: {
                headers: {
                    'user-id': result.id,
                },
            },
        });
    });

    getCurrentUser();

    if (process.env.APP_ENV !== 'local') {
        getActualVersion();
        getUserLegal.done.watch(() => onlineChatOptions());
    }

    Sentry.init({
        environment: process.env.APP_ENV,
        dsn: 'https://8e729eefc4c44c478cf804875f845e23@o305592.ingest.sentry.io/3701521',
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.2,
        allowUrls: process.env.STORE_DOMAIN,
    });
};
