import { Notify } from 'quasar';
import { isArray } from 'util';

export class Toast {
    static show(params) {
        Notify.create({
            ...params,
            position: 'top',
        });
    }

    static alert(message, timeout = 3000) {
        this.show({
            timeout: timeout,
            message: message,
            color: 'warning',
            icon: 'warning',
        });
    }

    static error(message, timeout = 3000) {
        const messages = isArray(message) ? message : [message];

        messages.forEach((message) => {
            this.show({
                timeout: timeout,
                message: message,
                color: 'negative',
                icon: 'error',
            });
        });
    }

    static success(message, timeout = 3000) {
        this.show({
            timeout: timeout,
            message: message,
            color: 'positive',
            icon: 'done',
        });
    }

    static info(message, timeout = 3000) {
        this.show({
            timeout: timeout,
            message: message,
            color: 'info',
            icon: 'info',
        });
    }
}
