import { getOrderServiceName } from 'config/index';

// eslint-disable-next-line no-useless-escape
export const emailRegex = /^[\w\.\+-]+@([a-zA-Z0-9-]{2,63}\.){1,2}[\w-]{2,63}$/iu;

export const orderServiceOptions = (keys) => {
    return keys.reduce((options, key) => {
        options.push({
            label: getOrderServiceName(key),
            value: key,
        });
        return options;
    }, []);
};

export const remapPagination = ({ perPage, totalAmount, page }) => ({
    rowsPerPage: perPage,
    rowsNumber: totalAmount,
    page,
});

export const redirectToAuth = () => {
    return (window.location = process.env.AUTH_URL);
};

export const stringToNumber = (string) => String(string).trim().replace(',', '.');

export const isEmpty = (obj) => {
    if (obj == null) return true;
    if (obj.constructor === Array || typeof obj === 'string') return obj.length === 0;
    for (var key in obj) {
        if (Object.hasOwnProperty.call(obj, key)) return false;
    }

    return true;
};

export const roundNumber = (number, scale = 2) => {
    let num = number;
    if (typeof number === 'string') {
        num = stringToNumber(num);
    }
    return +num.toFixed(scale);
};

export const browserVersion = (() => {
    const ua = navigator.userAgent;
    let tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return {
            name: 'IE',
            version: (tem[1] || ''),
        };
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\bOPR|Edge\/(\d+)/);
        if (tem != null) {
            return {
                name: 'Opera',
                version: tem[1],
            };
        }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1]);
    }
    return M;
})();