var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row q-col-gutter-sm wrap" }, [
    _c(
      "div",
      { staticClass: "col-7 q-gutter-y-md" },
      [
        _vm.parcelsRoutes.length
          ? _c(
              "div",
              [
                _c(
                  "q-breadcrumbs",
                  {
                    staticClass: "bg-white bordered q-px-md q-py-sm",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "separator",
                          fn: function() {
                            return [
                              _c("q-icon", {
                                attrs: {
                                  size: "1.1em",
                                  name: "arrow_forward",
                                  color: "primary"
                                }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      546188806
                    )
                  },
                  _vm._l(_vm.parcelsRoutes, function(city) {
                    return _c("q-breadcrumbs-el", {
                      key: "city_" + _vm.$attrs.id + "_" + (city && city.id),
                      attrs: { label: city ? city.name : "" }
                    })
                  }),
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm.logs.length
          ? _c(
              "q-list",
              {
                staticClass: "col bg-white",
                attrs: { dense: "", flat: "", bordered: "", separator: "" }
              },
              [
                _c(
                  "q-item",
                  [
                    _c("q-item-section", { staticClass: "col" }, [
                      _c("b", [_vm._v("Операция")])
                    ]),
                    _c("q-item-section", { staticClass: "col" }, [
                      _c("b", [_vm._v("Было")])
                    ]),
                    _c("q-item-section", { staticClass: "col" }, [
                      _c("b", [_vm._v("Стало")])
                    ]),
                    _c("q-item-section", { staticClass: "col" }, [
                      _c("b", [_vm._v("Дата")])
                    ])
                  ],
                  1
                ),
                _vm._l(_vm.filteredLogs, function(item) {
                  return _c(
                    "q-item",
                    { key: "log" + item.id },
                    [
                      _c(
                        "q-item-section",
                        { staticClass: "col", attrs: { side: "" } },
                        [_vm._v(_vm._s(_vm.operations[item.type]))]
                      ),
                      _c("q-item-section", { staticClass: "col" }, [
                        _vm._v(
                          _vm._s(_vm.getOperationName(item.type, item.was))
                        )
                      ]),
                      _c("q-item-section", { staticClass: "col" }, [
                        _vm._v(
                          _vm._s(_vm.getOperationName(item.type, item.now))
                        )
                      ]),
                      _c("q-item-section", { staticClass: "col" }, [
                        _vm._v(_vm._s(item.created_at))
                      ])
                    ],
                    1
                  )
                })
              ],
              2
            )
          : _c(
              "q-list",
              { staticClass: "col bg-white" },
              [
                _c(
                  "q-item",
                  [
                    _c("q-item-section", [
                      _vm._v("История операций отсутствует")
                    ])
                  ],
                  1
                )
              ],
              1
            )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-5" },
      [
        _c(
          "q-list",
          {
            staticClass: "col bg-white",
            attrs: { dense: "", flat: "", bordered: "" }
          },
          [
            !!_vm.finalpoint
              ? _c(
                  "q-item",
                  [
                    _c("q-item-section", { attrs: { side: "" } }, [
                      _vm._v("ПВЗ:")
                    ]),
                    _c("q-item-section", [
                      _vm._v(
                        "DP.express #" +
                          _vm._s(_vm.finalpoint.id) +
                          " @ " +
                          _vm._s(_vm.finalpoint.city) +
                          ", " +
                          _vm._s(_vm.finalpoint.address)
                      )
                    ])
                  ],
                  1
                )
              : _c(
                  "q-item",
                  [
                    _c("q-item-section", { attrs: { side: "" } }, [
                      _vm._v("Адрес доставки:")
                    ]),
                    _c("q-item-section", [
                      _vm._v(
                        _vm._s(
                          _vm.parcelsRoutes[1]
                            ? _vm.parcelsRoutes[1].full_name
                            : ""
                        ) +
                          ", " +
                          _vm._s(_vm.$attrs.address)
                      )
                    ])
                  ],
                  1
                ),
            _c(
              "q-item",
              [
                _c("q-item-section", { attrs: { side: "" } }, [
                  _vm._v("Получатель:")
                ]),
                _c("q-item-section", [
                  _vm._v(
                    _vm._s(_vm.$attrs.client_name) +
                      " " +
                      _vm._s(_vm.$attrs.client_phone)
                  )
                ])
              ],
              1
            ),
            _c(
              "q-item",
              [
                _c("q-item-section", { attrs: { side: "" } }, [
                  _vm._v("Дата доставки:")
                ]),
                _c("q-item-section", [
                  _vm._v(_vm._s(_vm.$attrs.delivery_date || "-"))
                ])
              ],
              1
            ),
            _c(
              "q-item",
              [
                _c("q-item-section", { attrs: { side: "" } }, [
                  _vm._v("Интервал доставки:")
                ]),
                _c("q-item-section", [
                  _vm._v(_vm._s(_vm.$attrs.delivery_time.join("-")))
                ])
              ],
              1
            ),
            _c(
              "q-item",
              [
                _c("q-item-section", { attrs: { side: "" } }, [_vm._v("Вес:")]),
                _c("q-item-section", [
                  _vm._v(_vm._s(_vm.$attrs.weight) + " кг.")
                ])
              ],
              1
            ),
            _c(
              "q-item",
              [
                _c("q-item-section", { attrs: { side: "" } }, [
                  _vm._v("Кол-во мест:")
                ]),
                _c("q-item-section", [_vm._v(_vm._s(_vm.$attrs.volume))])
              ],
              1
            ),
            _c(
              "q-item",
              [
                _c("q-item-section", { attrs: { side: "" } }, [
                  _vm._v("Габариты см. (ДхШхВ):")
                ]),
                _c("q-item-section", [
                  _vm._v(
                    _vm._s(_vm.$attrs.length || "-") +
                      "x" +
                      _vm._s(_vm.$attrs.width || "-") +
                      "x" +
                      _vm._s(_vm.$attrs.height || "-")
                  )
                ])
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _vm.data.length
          ? _c(
              "q-list",
              {
                staticClass: "col bg-white",
                attrs: { flat: "", bordered: "", separator: "" }
              },
              [
                _c(
                  "q-item",
                  [
                    _c("q-item-section", { staticClass: "col" }, [
                      _c("b", [
                        _vm._v("Наименование\n                    "),
                        _c("br"),
                        _vm._v("/артикул")
                      ])
                    ]),
                    _c("q-item-section", { staticClass: "col-3" }, [
                      _c("b", [_vm._v("Статус")])
                    ]),
                    _c("q-item-section", { staticClass: "col-1 ellipsis" }, [
                      _c("b", [_vm._v("Отправл.")])
                    ]),
                    _c("q-item-section", { staticClass: "col-1 ellipsis" }, [
                      _c("b", [_vm._v("Выдано")])
                    ]),
                    _c("q-item-section", { staticClass: "col-1" }, [
                      _c("b", [_vm._v("Стоимость")])
                    ]),
                    _c("q-item-section", { staticClass: "col-1" }, [
                      _c("b", [_vm._v("Объявл. ценность")])
                    ])
                  ],
                  1
                ),
                _vm._l(_vm.data, function(item) {
                  return _c(
                    "q-item",
                    { key: "data" + item.id },
                    [
                      item
                        ? _c(
                            "q-item-section",
                            [
                              _c("q-item-label", { staticClass: "ellipsis" }, [
                                _vm._v(_vm._s(item.name))
                              ]),
                              _c("q-item-label", { staticClass: "text-teal" }, [
                                _vm._v(_vm._s(item.article))
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("q-item-section", { staticClass: "col-3" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.getDataStatusName(item.status)) +
                            "\n                "
                        )
                      ]),
                      _c("q-item-section", { staticClass: "col-1" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(item.origin_qty) +
                            "\n                "
                        )
                      ]),
                      _c("q-item-section", { staticClass: "col-1" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(item.qty) +
                            "\n                "
                        )
                      ]),
                      _c("q-item-section", { staticClass: "col-1" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(item.price ? item.price + " руб." : "-") +
                            "\n                "
                        )
                      ]),
                      _c("q-item-section", { staticClass: "col-1" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              item.declared_price
                                ? item.declared_price + " руб."
                                : "-"
                            ) +
                            "\n                "
                        )
                      ])
                    ],
                    1
                  )
                })
              ],
              2
            )
          : _c(
              "q-list",
              { staticClass: "col bg-white" },
              [
                _c(
                  "q-item",
                  [_c("q-item-section", [_vm._v("Заказ не имеет вложений")])],
                  1
                )
              ],
              1
            )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }