import { connection } from 'api/connection';
import { handleRequest } from './request';
import { ApiInstance } from './ApiInstance';

class UserApiInstance extends ApiInstance {
    constructor (url) {
        super(url);
    }

    async getLegal() {
        return await handleRequest(connection.get(`${this.baseUrl}/legal`));
    }

    async getMessages() {
        return await handleRequest(connection.get(`${this.baseUrl}/messages`));
    }
}

export const UserApi = new UserApiInstance(`${process.env.API_ENDPOINT}/user`);
