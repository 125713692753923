var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-card",
    { staticClass: "my-card bg-grey-1", attrs: { flat: "" } },
    [
      _c("q-card-section", { staticClass: "row" }, [
        _c("div", { staticClass: "col text-h6" }, [_vm._v(_vm._s(_vm.title))]),
        _c(
          "div",
          { staticClass: "col text-right headerButtons" },
          [
            _vm._t("headerButtons"),
            _vm.$slots.headerMenu
              ? _c(
                  "q-btn",
                  {
                    attrs: {
                      color: "grey-7",
                      round: "",
                      flat: "",
                      icon: "more_vert"
                    }
                  },
                  [
                    _c(
                      "q-menu",
                      { attrs: { cover: "", "auto-close": "" } },
                      [_vm._t("headerMenu")],
                      2
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          2
        )
      ]),
      _c("q-separator"),
      _vm.$slots.headerForm
        ? [_c("q-card-section", [_vm._t("headerForm")], 2), _c("q-separator")]
        : _vm._e(),
      _c("q-table", {
        attrs: {
          data: _vm.tableData,
          columns: _vm.columns,
          "row-key": _vm.rowKey || "id",
          "visible-columns": _vm.visibleColumns,
          pagination: _vm.localPagination,
          loading: _vm.loading,
          "rows-per-page-options": _vm.rowsPerPageOptions
        },
        on: {
          "update:pagination": function($event) {
            _vm.localPagination = $event
          },
          request: function(ref) {
            var pagination = ref.pagination

            return _vm.$emit("page-change", pagination)
          }
        },
        scopedSlots: _vm._u(
          [
            _vm.visibleColumns
              ? {
                  key: "top-right",
                  fn: function() {
                    return [
                      _c(
                        "q-btn-dropdown",
                        {
                          attrs: {
                            size: "sm",
                            color: "grey-6",
                            unelevated: "",
                            outline: "",
                            icon: "table_chart"
                          }
                        },
                        [
                          _c("div", { staticClass: "row no-wrap q-pa-md" }, [
                            _c(
                              "div",
                              { staticClass: "column" },
                              _vm._l(_vm.togglableColumns, function(col, i) {
                                return _c("q-toggle", {
                                  key: "toggle_" + i,
                                  attrs: {
                                    val: col.name,
                                    label: col.label,
                                    size: "xs"
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.updateVisibleColumns()
                                    }
                                  },
                                  model: {
                                    value: _vm.visibleCols,
                                    callback: function($$v) {
                                      _vm.visibleCols = $$v
                                    },
                                    expression: "visibleCols"
                                  }
                                })
                              }),
                              1
                            )
                          ])
                        ]
                      )
                    ]
                  },
                  proxy: true
                }
              : null,
            {
              key: "header",
              fn: function(props) {
                return [
                  _c(
                    "q-tr",
                    { attrs: { props: props } },
                    [
                      _vm.expandKey
                        ? _c("q-th", { attrs: { "auto-width": "" } })
                        : _vm._e(),
                      _vm._l(
                        props.cols.filter(function(el) {
                          return el.name != _vm.expandKey
                        }),
                        function(col) {
                          return _c(
                            "q-th",
                            { key: col.name, attrs: { props: props } },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(col.label) +
                                  "\n                "
                              )
                            ]
                          )
                        }
                      )
                    ],
                    2
                  )
                ]
              }
            },
            {
              key: "body",
              fn: function(props) {
                return [
                  _c(
                    "q-tr",
                    { attrs: { props: props } },
                    [
                      _vm.expandKey
                        ? _c(
                            "q-td",
                            {
                              key: "m_" + props.row.id,
                              attrs: { "auto-width": "" }
                            },
                            [
                              _c("q-btn", {
                                attrs: {
                                  size: "sm",
                                  color: "accent",
                                  round: "",
                                  dense: "",
                                  unelevated: "",
                                  icon: props.expand ? "remove" : "add"
                                },
                                on: {
                                  click: function($event) {
                                    props.expand = !props.expand
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._l(
                        props.cols.filter(function(el) {
                          return el.name != _vm.expandKey
                        }),
                        function(col) {
                          return _c(
                            "q-td",
                            { key: col.name, attrs: { props: props } },
                            [
                              col.name == "actions"
                                ? _c(
                                    "div",
                                    { staticClass: "q-gutter-x-sm" },
                                    [
                                      !!_vm.actionWidget
                                        ? _c(
                                            _vm.actionWidget,
                                            _vm._b(
                                              { tag: "div" },
                                              "div",
                                              props.row,
                                              false
                                            )
                                          )
                                        : _vm._e(),
                                      _vm.editable
                                        ? _c("q-btn", {
                                            attrs: {
                                              icon: "edit",
                                              size: "sm",
                                              outline: "",
                                              color: "indigo"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.$emit(
                                                  "edit",
                                                  props.key
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e(),
                                      _vm.deletable
                                        ? _c("q-btn", {
                                            attrs: {
                                              icon: "delete",
                                              size: "sm",
                                              outline: "",
                                              color: "red"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.handleDelete(
                                                  props.key,
                                                  props.row.name
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      col.badge && col.value !== "-"
                                        ? _c("q-badge", {
                                            attrs: {
                                              color: col.badge,
                                              label: col.value
                                            }
                                          })
                                        : _c("span", [
                                            _vm._v(_vm._s(col.value))
                                          ])
                                    ],
                                    1
                                  )
                            ]
                          )
                        }
                      )
                    ],
                    2
                  ),
                  _c(
                    "q-tr",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: props.expand,
                          expression: "props.expand"
                        }
                      ],
                      key: "e_" + props.row.id,
                      staticClass: "q-virtual-scroll--with-prev",
                      attrs: { props: props }
                    },
                    [
                      _c(
                        "q-td",
                        {
                          staticClass: "bg-amber-2",
                          attrs: { colspan: "100%" }
                        },
                        [
                          !!_vm.expandedWidget && _vm.expandKey
                            ? _c(
                                _vm.expandedWidget,
                                _vm._b(
                                  { tag: "div", staticClass: "text-left" },
                                  "div",
                                  props.row,
                                  false
                                )
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      }),
      _vm._t("appends")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }