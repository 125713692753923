<template>
    <q-card flat>
        <q-markup-table flat>
            <thead v-if="hasLabel">
                <tr>
                    <th v-for="(option, optionIndex) in optionsFiltered" :key="optionIndex">
                        <div class="text-left">{{ option.label }}</div>
                    </th>
                    <th v-if="editable" side class="expanding-list-action" />
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, index) in value" :key="index">
                    <td v-for="(option, optionIndex) in optionsFiltered" :key="optionIndex">
                        <base-select
                            v-if="option.type === 'select'"
                            dense
                            :value="row[option.name]"
                            :options="selectOptions"
                            emit-value
                            map-options
                            :readonly="option.readonly || !editable"
                            @input="newValue => handleUpdate(newValue, index, option.name)"
                        />
                        <base-input
                            v-else
                            dense
                            :type="value[index].name === 'phone' ? 'tel' : option.type || 'text'"
                            :value="row[option.name]"
                            :placeholder="option.placeholder"
                            :readonly="option.readonly || !editable"
                            @input="newValue => handleUpdate(newValue, index, option.name)"
                        />
                    </td>
                    <td v-if="editable && control" side class="expanding-list-action">
                        <delete-btn size="sm" @click="handleRowDelete(index)" />
                    </td>
                </tr>
            </tbody>
        </q-markup-table>
        <q-card-actions v-if="editable && control">
            <q-btn outline color="green" icon="add" title="Добавить" size="sm" @click="handleAppendRow()" />
        </q-card-actions>
    </q-card>
</template>


<script>
import DeleteBtn from 'components/Buttons/DeleteBtn';
const defaultOptions = [
    { name: 'name', label: 'Name', value: null, hidden: false, placeholder: 'Name' },
    { name: 'value', label: 'Value', value: null, hidden: false, placeholder: 'Value' },
];
export default {
    components: { DeleteBtn },

    props: {
        value: {
            type: Array,
            default: () => [],
        },
        options: {
            type: Array,
            default: () => defaultOptions,
        },
        selectOptions: {
            type: Array,
        },
        editable: {
            type: Boolean,
            default: true,
        },
        control: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        hasLabel() {
            return this.options.some((row) => row.label);
        },
        optionsFiltered() {
            // filter options where default value is set and shouldn't be edited
            return this.options.filter(el => !el.hidden);
        },
    },

    methods: {
        handleRowDelete(toDelete) {
            const newValue = this.value.filter((row, index) => index !== toDelete);
            this.$emit('change', newValue);
        },

        handleUpdate(value, index, key) {
            const newValue = [...this.value];
            newValue[index][key] = value;
            this.$emit('change', newValue);
        },

        handleAppendRow() {
            const newRow = this.options.reduce((row, option) => {
                row[option.name] = option.hidden ? option.value : null;
                return row;
            }, {});
            const newValue = [...this.value, newRow];
            this.$emit('change', newValue);
        },
    },
};
</script>

<style lang="scss" scoped>
.expanding-list-action {
    width: 100px;
}
</style>
