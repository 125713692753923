import { ApiInstance } from './ApiInstance';
import { handleRequest } from './request';
import { connection } from './connection';

class LegalsApiInstance extends ApiInstance {
    constructor (url) {
        super(url);
    }

    async updateBank(params) {
        return await handleRequest(connection.patch(`${this.baseUrl}/${params.legal_id}/updateBank`, params));
    }

    async createBank(params) {
        return await handleRequest(connection.post(`${this.baseUrl}/${params.legal_id}/createBank`, params));
    }
}

export const LegalsApi = new LegalsApiInstance(`${process.env.API_ENDPOINT}/legals`);
