var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-card",
    { attrs: { flat: "" } },
    [
      _c("q-markup-table", { attrs: { flat: "" } }, [
        _vm.hasLabel
          ? _c("thead", [
              _c(
                "tr",
                [
                  _vm._l(_vm.optionsFiltered, function(option, optionIndex) {
                    return _c("th", { key: optionIndex }, [
                      _c("div", { staticClass: "text-left" }, [
                        _vm._v(_vm._s(option.label))
                      ])
                    ])
                  }),
                  _vm.editable
                    ? _c("th", {
                        staticClass: "expanding-list-action",
                        attrs: { side: "" }
                      })
                    : _vm._e()
                ],
                2
              )
            ])
          : _vm._e(),
        _c(
          "tbody",
          _vm._l(_vm.value, function(row, index) {
            return _c(
              "tr",
              { key: index },
              [
                _vm._l(_vm.optionsFiltered, function(option, optionIndex) {
                  return _c(
                    "td",
                    { key: optionIndex },
                    [
                      option.type === "select"
                        ? _c("base-select", {
                            attrs: {
                              dense: "",
                              value: row[option.name],
                              options: _vm.selectOptions,
                              "emit-value": "",
                              "map-options": "",
                              readonly: option.readonly || !_vm.editable
                            },
                            on: {
                              input: function(newValue) {
                                return _vm.handleUpdate(
                                  newValue,
                                  index,
                                  option.name
                                )
                              }
                            }
                          })
                        : _c("base-input", {
                            attrs: {
                              dense: "",
                              type:
                                _vm.value[index].name === "phone"
                                  ? "tel"
                                  : option.type || "text",
                              value: row[option.name],
                              placeholder: option.placeholder,
                              readonly: option.readonly || !_vm.editable
                            },
                            on: {
                              input: function(newValue) {
                                return _vm.handleUpdate(
                                  newValue,
                                  index,
                                  option.name
                                )
                              }
                            }
                          })
                    ],
                    1
                  )
                }),
                _vm.editable && _vm.control
                  ? _c(
                      "td",
                      {
                        staticClass: "expanding-list-action",
                        attrs: { side: "" }
                      },
                      [
                        _c("delete-btn", {
                          attrs: { size: "sm" },
                          on: {
                            click: function($event) {
                              return _vm.handleRowDelete(index)
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              2
            )
          }),
          0
        )
      ]),
      _vm.editable && _vm.control
        ? _c(
            "q-card-actions",
            [
              _c("q-btn", {
                attrs: {
                  outline: "",
                  color: "green",
                  icon: "add",
                  title: "Добавить",
                  size: "sm"
                },
                on: {
                  click: function($event) {
                    return _vm.handleAppendRow()
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }