var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticStyle: { float: "left", "font-family": "monospace" },
        style: _vm.bordered && "border: 1px dashed #666"
      },
      [
        _vm.withLogo
          ? _c(
              "div",
              { staticStyle: { float: "left", margin: "10px 5px 0 10px" } },
              [
                _c("img", {
                  attrs: {
                    src: "images/DP_Express_MONO.png",
                    width: "100px",
                    alt: "dp.express"
                  }
                })
              ]
            )
          : _vm._e(),
        _vm.label
          ? _c(
              "div",
              { staticStyle: { "text-align": "center", "margin-top": "5px" } },
              [_vm._v(_vm._s(_vm.label))]
            )
          : _vm._e(),
        _vm.number
          ? _c(
              "div",
              { staticStyle: { "text-align": "center", "margin-top": "5px" } },
              [_vm._v(_vm._s(_vm.number))]
            )
          : _vm._e(),
        _c("vue-barcode", {
          attrs: {
            value: _vm.barcode,
            height: _vm.height,
            width: _vm.width,
            "display-value": _vm.displayValue
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }