var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-dialog",
    _vm._b(
      {
        ref: "dialog",
        attrs: { value: _vm.value },
        on: { hide: _vm.onDialogHide }
      },
      "q-dialog",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "q-card",
        {
          staticClass: "q-dialog-plugin",
          style: "width: " + _vm.width + "; max-width: 80vw;"
        },
        [
          _c(
            "q-card-section",
            { staticClass: "row items-center" },
            [
              _c("div", { staticClass: "text-h6" }, [
                _vm._v(_vm._s(_vm.title))
              ]),
              _c("q-space"),
              _c("q-btn", {
                directives: [{ name: "close-popup", rawName: "v-close-popup" }],
                attrs: { icon: "close", flat: "", round: "", dense: "" }
              })
            ],
            1
          ),
          _c("q-separator"),
          _c(
            "q-card-section",
            {
              staticClass: "q-pa-none scroll",
              staticStyle: { "max-height": "80vh" }
            },
            [_vm._t("dialog-body")],
            2
          ),
          _c("q-separator"),
          !_vm.noActions
            ? _c(
                "q-card-actions",
                { staticClass: "q-pa-md", attrs: { align: "between" } },
                [
                  _c("q-btn", {
                    attrs: {
                      color: "grey-7",
                      outline: "",
                      label: _vm.cancelLabel
                    },
                    on: { click: _vm.onCancelClick }
                  }),
                  _vm._t("dialog-footer", function() {
                    return [
                      _c("q-btn", {
                        attrs: {
                          color: "primary",
                          unelevated: "",
                          label: _vm.okLabel,
                          loading: _vm.loading,
                          disable: _vm.disabledSave
                        },
                        on: { click: _vm.onOKClick }
                      })
                    ]
                  })
                ],
                2
              )
            : _vm._e(),
          _c(
            "q-inner-loading",
            { attrs: { showing: _vm.pending } },
            [
              _c("q-spinner-gears", {
                attrs: { size: "50px", color: "primary" }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }