var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "loading-wrapper",
    { attrs: { value: true, size: "xl" } },
    [_c("q-layout", { attrs: { view: "lHh LpR fff" } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }