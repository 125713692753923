<template>
    <div id="q-app">
        <loading-layout v-if="loading" />
        <main-layout v-else>
            <router-view />
        </main-layout>
    </div>
</template>

<script>
import { getCurrentUser } from 'store/auth';

import MainLayout from 'components/layouts/MainLayout';
import LoadingLayout from 'components/layouts/LoadingLayout';

export default {
    name: 'App',

    components: {
        MainLayout,
        LoadingLayout,
    },

    effector: {
        loading: getCurrentUser.pending,
    },
};
</script>
