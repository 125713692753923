import { render, staticRenderFns } from "./AppSidebar.vue?vue&type=template&id=4d353c59&"
import script from "./AppSidebar.vue?vue&type=script&lang=js&"
export * from "./AppSidebar.vue?vue&type=script&lang=js&"
import style0 from "./AppSidebar.vue?vue&type=style&index=0&lang=scss&scope=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QList from 'quasar/src/components/item/QList.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QDrawer,QList,QExpansionItem,QItem,QItemSection,QIcon});
/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4d353c59')) {
      api.createRecord('4d353c59', component.options)
    } else {
      api.reload('4d353c59', component.options)
    }
    module.hot.accept("./AppSidebar.vue?vue&type=template&id=4d353c59&", function () {
      api.rerender('4d353c59', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/app/AppSidebar.vue"
export default component.exports