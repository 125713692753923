<template>
    <div class="row q-col-gutter-sm wrap">
        <div class="col-7 q-gutter-y-md">
            <div v-if="parcelsRoutes.length">
                <q-breadcrumbs class="bg-white bordered q-px-md q-py-sm">
                    <template #separator>
                        <q-icon
                            size="1.1em"
                            name="arrow_forward"
                            color="primary"
                        />
                    </template>
                    <q-breadcrumbs-el v-for="city in parcelsRoutes" :key="`city_${$attrs.id}_${city && city.id}`" :label="city ? city.name : ''" />
                </q-breadcrumbs>
            </div>

            <q-list v-if="logs.length" dense flat bordered separator class="col bg-white">
                <q-item>
                    <q-item-section class="col"><b>Операция</b></q-item-section>
                    <q-item-section class="col"><b>Было</b></q-item-section>
                    <q-item-section class="col"><b>Стало</b></q-item-section>
                    <q-item-section class="col"><b>Дата</b></q-item-section>
                </q-item>
                <q-item v-for="item in filteredLogs" :key="`log${item.id}`">
                    <q-item-section side class="col">{{ operations[item.type] }}</q-item-section>
                    <q-item-section class="col">{{ getOperationName(item.type, item.was) }}</q-item-section>
                    <q-item-section class="col">{{ getOperationName(item.type, item.now) }}</q-item-section>
                    <q-item-section class="col">{{ item.created_at }}</q-item-section>
                </q-item>
            </q-list>

            <q-list v-else class="col bg-white">
                <q-item>
                    <q-item-section>История операций отсутствует</q-item-section>
                </q-item>
            </q-list>
        </div>

        <div class="col-5">
            <q-list dense flat bordered class="col bg-white">
                <q-item v-if="!!finalpoint">
                    <q-item-section side>ПВЗ:</q-item-section>
                    <q-item-section>DP.express #{{ finalpoint.id }} @ {{ finalpoint.city }}, {{ finalpoint.address }}</q-item-section>
                </q-item>
                <q-item v-else>
                    <q-item-section side>Адрес доставки:</q-item-section>
                    <q-item-section>{{ parcelsRoutes[1] ? parcelsRoutes[1].full_name : '' }}, {{ $attrs.address }}</q-item-section>
                </q-item>
                <q-item>
                    <q-item-section side>Получатель:</q-item-section>
                    <q-item-section>{{ $attrs.client_name }} {{ $attrs.client_phone }}</q-item-section>
                </q-item>
                <q-item>
                    <q-item-section side>Дата доставки:</q-item-section>
                    <q-item-section>{{ $attrs.delivery_date || '-' }}</q-item-section>
                </q-item>
                <q-item>
                    <q-item-section side>Интервал доставки:</q-item-section>
                    <q-item-section>{{ $attrs.delivery_time.join('-') }}</q-item-section>
                </q-item>
                <q-item>
                    <q-item-section side>Вес:</q-item-section>
                    <q-item-section>{{ $attrs.weight }} кг.</q-item-section>
                </q-item>
                <q-item>
                    <q-item-section side>Кол-во мест:</q-item-section>
                    <q-item-section>{{ $attrs.volume }}</q-item-section>
                </q-item>
                <q-item>
                    <q-item-section side>Габариты см. (ДхШхВ):</q-item-section>
                    <q-item-section>{{ $attrs.length || '-' }}x{{ $attrs.width || '-' }}x{{ $attrs.height || '-' }}</q-item-section>
                </q-item>
            </q-list>
        </div>

        <div class="col-12">
            <q-list v-if="data.length" flat bordered separator class="col bg-white">
                <q-item>
                    <q-item-section class="col"><b>Наименование
                        <br />/артикул</b></q-item-section>
                    <q-item-section class="col-3"><b>Статус</b></q-item-section>
                    <q-item-section class="col-1 ellipsis"><b>Отправл.</b></q-item-section>
                    <q-item-section class="col-1 ellipsis"><b>Выдано</b></q-item-section>
                    <q-item-section class="col-1"><b>Стоимость</b></q-item-section>
                    <q-item-section class="col-1"><b>Объявл. ценность</b></q-item-section>
                </q-item>
                <q-item v-for="item in data" :key="`data${item.id}`">
                    <q-item-section v-if="item">
                        <q-item-label class="ellipsis">{{ item.name }}</q-item-label>
                        <q-item-label class="text-teal">{{ item.article }}</q-item-label>
                    </q-item-section>

                    <q-item-section class="col-3">
                        {{ getDataStatusName(item.status) }}
                    </q-item-section>

                    <q-item-section class="col-1">
                        {{ item.origin_qty }}
                    </q-item-section>
                    <q-item-section class="col-1">
                        {{ item.qty }}
                    </q-item-section>
                    <q-item-section class="col-1">
                        {{ item.price ? (item.price + ' руб.') : '-' }}
                    </q-item-section>
                    <q-item-section class="col-1">
                        {{ item.declared_price ? (item.declared_price + ' руб.') : '-' }}
                    </q-item-section>
                </q-item>
            </q-list>
            <q-list v-else class="col bg-white">
                <q-item>
                    <q-item-section>Заказ не имеет вложений</q-item-section>
                </q-item>
            </q-list>
        </div>
    </div>
</template>

<script>
import { operations } from 'config/';
import { getOrderStateName, getOrderStageName, getOrderStatusName, getDataStatusName } from 'config/';

export default {
    props: {
        data: {
            type: Array,
            required: true,
        },
        logs: {
            type: Array,
            required: true,
        },
        parcels: {
            type: Array,
            required: true,
        },
        finalpoint: {
            type: Object,
        },
    },

    data() {
        return {
            operations,
        };
    },
    computed: {
        filteredLogs() {
            return this.logs.filter(el => Object.keys(this.operations).includes(el.type));
        },
        parcelsRoutes(){
            const parcels = this.parcels.slice().length > 1 ? this.parcels.slice().sort((a, b) => a.id - b.id) : this.parcels.slice();
            return parcels.map(el => [el.origin_model, el.destination_model]).flat();
        },
    },

    methods: {
        getDataStatusName,

        getOperationName(type, val) {
            return {
                state: getOrderStateName(val),
                stage: getOrderStageName(val),
                status: getOrderStatusName(val),
                parcel_status: val,
            }[type];
        },
    },
};
</script>
