var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "q-layout",
    { attrs: { view: "lHh LpR fff" } },
    [
      _c("app-header", {
        model: {
          value: _vm.minify,
          callback: function($$v) {
            _vm.minify = $$v
          },
          expression: "minify"
        }
      }),
      _c("app-sidebar", {
        model: {
          value: _vm.minify,
          callback: function($$v) {
            _vm.minify = $$v
          },
          expression: "minify"
        }
      }),
      _c(
        "q-page-container",
        [
          _c(
            "q-page",
            { staticClass: "bg-layout" },
            [
              _c(
                "transition",
                { attrs: { name: "fade", mode: "out-in" } },
                [_c("router-view")],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("app-footer"),
      _c("app-notify")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }